<template>
<main id="company">
  <pageHead class="bg-[url('@/assets/img/page/company/pagehead.jpg')]">
    <template #pageTitle>会社情報</template>
    <a class="pageHeadButton" href="#about">会社概要</a>
    <a class="pageHeadButton" href="#philosophy">理念</a>
    <a class="pageHeadButton" href="#greetings">代表挨拶</a>
  </pageHead>
  <theAbout />
  <thePhilosophy />
  <theGreetings />
  <theSns></theSns>
  <pageFooter />
</main>
</template>

<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import theAbout from '@/components/page/company/theAbout.vue'
import thePhilosophy from '@/components/page/company/thePhilosophy.vue'
import theGreetings from '@/components/page/company/theGreetings.vue'
import theSns from '@/components/top/theSns.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
  components: {
    pageHead,
    theAbout,
    thePhilosophy,
    theGreetings,
    theSns,
    pageFooter,
  }
}
</script>

<style lang="scss" scoped>

</style>