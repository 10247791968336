<template>
<headerArea class="
    absolute
    top-0 left-0
    right-0 z-[99]
    hidden
    lg:flex
"/>
<headerArea class="
    on
    z-[99]
    lg:fixed
    lg:top-0
    lg:left-0
    lg:right-0
    lg:z-40
    lg:backdrop-blur-lg
    lg:backdrop-brightness-110
"/>
<router-view class="bg-white" />
<footerArea/>
</template>
  
<script>
import headerArea from './components/layout/headerArea.vue'
import footerArea from './components/layout/footerArea.vue'

export default {
  name: 'App',
  components: {
      headerArea,
      footerArea
  },
  methods : {
  setMeta(route){
      // タイトルを設定
      if(route.meta.title){
          let setTitle = route.meta.title;
          document.title = setTitle;
      }
      // ディスクリプションを設定
      if(route.meta.desc){
          let setDesc = route.meta.desc;
          document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
  }
  },
  mounted(){
        let route = this.$route;
        this.setMeta(route);
    },
    watch: { 
        '$route' (route) {
            this.setMeta(route);
        }
    }
}

//-vh
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('load', setVh);
</script>