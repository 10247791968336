import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServiceHealthView from "../views/ServiceHealthView.vue";
import ServiceCareView from "../views/ServiceCareView.vue";
import ServiceAssetView from "../views/ServiceAssetView.vue";
import GolfCourceView from "../views/GolfCourceView.vue";
// import GolfCourceRecommendView from "../views/GolfCourceRecommendView.vue";
import CompanyView from "../views/CompanyView.vue";
import AccessView from "../views/AccessView.vue";
import ContactView from "../views/ContactView.vue";
import PrivacyView from "../views/PrivacyView.vue";
import NotFoundErrorView from "../views/NotFoundErrorView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社は、若年性認知症を始めとする認知症予防の健康管理、介護管理、資産管理の3つを軸に事業を行なっております。",
    },
  },
  {
    path: "/health",
    name: "serviceHealth",
    component: ServiceHealthView,
    meta: {
      title: "健康管理 - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社の健康管理事業について掲載されています。",
    },
  },
  {
    path: "/care",
    name: "serviceCare",
    component: ServiceCareView,
    meta: {
      title: "介護管理 - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社の介護管理事業について掲載されています。",
    },
  },
  {
    path: "/asset",
    name: "serviceAsset",
    component: ServiceAssetView,
    meta: {
      title: "資産管理 - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社の資産管理事業について掲載されています。",
    },
  },
  {
    path: "/golf-stock",
    name: "golfCource",
    component: GolfCourceView,
    meta: {
      title: "弊社で在庫のある会員権 - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社で在庫のあるゴルフ場会員権を確認することができます。",
    },
  },
  // {
  //   path: "/golf-recommend",
  //   name: "golfCourceRecommend",
  //   component: GolfCourceRecommendView,
  //   meta: {
  //     title:
  //       "私たちがおすすめしているゴルフ場一覧 - グランドプロジェクト株式会社",
  //     desc: "グランドプロジェクト株式会社で取り扱っているおすすめのゴルフ場会員権を確認することができます。",
  //   },
  // },
  {
    path: "/company",
    name: "company",
    component: CompanyView,
    meta: {
      title: "会社情報 - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社の会社概要、理念、代表挨拶を確認することができます。",
    },
  },
  {
    path: "/access",
    name: "access",
    component: AccessView,
    meta: {
      title: "アクセス - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社のアクセス情報をすることができます。",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      title: "お問い合わせ - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社へのお問い合わせを行うことができます。",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
    meta: {
      title: "プライバシーポリシー - グランドプロジェクト株式会社",
      desc: "グランドプロジェクト株式会社Webサイトのプライバシーポリシーを確認することができます。",
    },
  },

  // 404
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    component: NotFoundErrorView,
  },
];

const scrollBehavior = (to) => {
  if (to.hash) {
    return {
      el: to.hash,
      behavior: "smooth",
    };
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
  mode: "history",
  base: process.env.BASE_URL,
});

export default router;
