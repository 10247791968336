<template>
<div class="w-f-cont">
  <div class="flex justify-center items-center gap-7 mb-5 lg:hidden">
    <img src="@/assets/img/common/scrollArrowLeft.svg" alt="">
    <p class="leading-7 font-bold text-center">スクロールして<br>ご覧いただけます</p>
    <img src="@/assets/img/common/scrollArrowRight.svg" alt="">
  </div>
  <div class="overflow-x-scroll whitespace-nowrap">
    <table class="table-vertical border-collapse table-auto w-full">
      <tbody>
        <tr>
          <th scope="col">都道府県</th>
          <th scope="col">コース名</th>
          <th scope="col">種類</th>
          <th scope="col">口数</th>
          <th scope="col">名義書換料</th>
          <th scope="col">年会費</th>
        </tr>
        <tr>
          <td>茨城県</td>
          <td>JGM笠間ゴルフクラブ</td>
          <td>個人/正</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
        </tr>
        <tr>
          <td>茨城県</td>
          <td>JGMやさと石岡ゴルフクラブ</td>
          <td>個人/正</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
        </tr>
        <tr>
          <td>栃木県</td>
          <td>JGM宇都宮ゴルフクラブ</td>
          <td>個人/正</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
          <td>お問合わせください</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>

import { getGolfStocksData } from "@/services/WordpressDataService";

export default {

  data(){
    return {
      golfstockList: [],
    }
  },

  async created(){
    // Get GolfStock list data from JSON
    // JSONから会員権在庫のあるゴルフ場一覧のデータ取得
    this.golfstockList = await getGolfStocksData();
  },

  methods: {
    // Format Currency to JPY
    formatCurrency(value) {
        if(value){
            return value.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'});
        }
        return "-";
    },
  }

}
</script>

<style lang="scss" scoped>
</style>