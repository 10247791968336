import axios from "axios";

export async function getPosts(typeId){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`${process.env.BASE_URL}json/posts.json`, headers)

    var typeValue;
    switch (typeId) {
        case 1:
            typeValue = "会員権の在庫があるゴルフ場";
            break;
        case 2:
            typeValue = "おすすめのゴルフ場";
            break;
    }

    // Filter by Type
    if(typeId){
        return response.data.filter(
            function(data){ 
                return data.type == typeValue;
            }
        )
    }else{
        // Return All
        return response.data;
    }
}

export async function getPostByID(id){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`${process.env.BASE_URL}json/posts.json`, headers)
    return response.data.filter(
        function(data){ 
            return data.id == id;
        }
    )[0];
}


export async function getGolfCourseData(){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`${process.env.BASE_URL}json/posts_1.json`, headers)

    // Return All
    return response.data;
}

export async function getGolfStocksData(){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`${process.env.BASE_URL}json/posts_2.json`, headers)

    // Return All
    return response.data;
}