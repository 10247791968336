<template>
<main id="notfound">
  <pageHead class="bg-gy">
    <template #pageTitle>ページが見つかりませんでした</template>
  </pageHead>
  <div class="py-l-block w-s-cont m-auto leading-loose">
    <p class="lg:text-center">このページは削除・変更されたか、一時的にアクセスできない可能性があります。
      <br/>お手数となりますが、当サイトトップページからお探しください。</p>
    <router-link class="btn btn-bl mt-32-40 mx-auto" to="/">トップページへ</router-link>
  </div>
  <pageFooter></pageFooter>
</main>
</template>

<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
  components: {
    pageHead,
    pageFooter,
  },
}
</script>

<style lang="scss" scoped>

</style>