<template>
<div id="operation" class="w-m-cont m-auto py-l-block leading-loose">
  <p class="mb-60-80">当事業は、グランドプロジェクトのグループ企業である<a class="underline" href="https://grandcaresystem.co.jp" target="_blank">グランドケアシステム株式会社</a>が運営を行なっています。</p>
  <h3 class="h3-auto">運営</h3>
  <table class="table-horizontal w-full">
    <tr>
      <th>有料介護施設</th>
      <td>グランドキャッスル</td>
    </tr>
    <tr>
      <th>訪問介護事業所</th>
      <td>ONE・ハート</td>
    </tr>
    <tr>
      <th>登録従事者</th>
      <td>
        <ul class="ul-auto">
          <li>正看護師：3名</li>
          <li>准看護師：6名</li>
          <li>機能訓練士：3名</li>
          <li>介護支援専門員：3名</li>
          <li>介護福祉士：8名</li>
          <li>実務者研修修了：17名</li>
          <li>初任者研修修了：18名</li>
        </ul>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>