import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/main.scss'

// Mock Server for Development Enviroment
// if (process.env.NODE_ENV === 'development') {
//     const { worker } = require('./mocks/browser')
//     worker.start()
//   }

// Application create and start
createApp(App)
.use(router, axios, VueAxios)
.mount('#app')
