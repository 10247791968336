<template>
<main id="golfCource">
    <pageHead class="bg-[url('@/assets/img/page/serviceHealth/pagehead.jpg')]">
      <template #pageTitle>弊社で会員権在庫のあるゴルフ場一覧</template>
    </pageHead>
    <golfCourceListStock class="py-l-block w-f-cont m-auto leading-loose" />
    <pageFooter></pageFooter>
</main>
</template>
    
<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import golfCourceListStock from '@/components/page/golf/golfCourceListStock.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
  components: {
    pageHead,
    golfCourceListStock,
    pageFooter,
  },
  mounted () {
  }
}
</script>
    
<style lang="scss">
</style>