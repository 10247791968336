<template>
<section id="greetings" class="py-l-block leading-loose w-f-cont m-auto">
  <h2 class="h2-auto">代表挨拶</h2>
  <greetingsDetail class="w-m-cont m-auto" />
</section>
</template>

<script>
import greetingsDetail from '@/components/page/company/greetingsDetail.vue'

export default {
  components: {
    greetingsDetail
  }
}
</script>

<style lang="scss" scoped>

</style>