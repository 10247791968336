<template>
<div class="py-60-80 bg-bl">
  <div class="w-f-cont mx-auto mb-32-40">
    <ul class="pageFooter-link-top">
      <li>
          <router-link class="font-serif after:content-['01']" to="/health">
              <picture class="img-wrap layer-bl-70">
                  <source type="image/webp" srcset="@/assets/img/top/service01.webp"/>
                  <img src="@/assets/img/top/service01.jpg"  alt=""/>
              </picture>
              <span>健康管理</span>
          </router-link>
      </li>
      <li>
          <router-link class="font-serif after:content-['02']" to="/care">
              <picture class="img-wrap layer-bl-70">
                  <source type="image/webp" srcset="@/assets/img/top/service02.webp"/>
                  <img src="@/assets/img/top/service02.jpg"  alt=""/>
              </picture>
              <span>介護管理</span>
          </router-link>
      </li>
      <li>
          <router-link class="font-serif after:content-['03']" to="/asset">
              <picture class="img-wrap layer-bl-70">
                  <source type="image/webp" srcset="@/assets/img/top/service03.webp"/>
                  <img src="@/assets/img/top/service03.jpg"  alt=""/>
              </picture>
              <span>資産管理</span>
          </router-link>
      </li>
    </ul>
  </div>
  <div class="
    w-f-cont
    m-auto
    flex
    flex-col
    md:flex-row
    gap-8
    [&>a]:leading-[100px]
    [&>a]:bg-white
    [&>a]:transition
    [&>a]:flex
    [&>a]:items-center
    [&>a]:w-full
    [&>a]:p-0
    [&>a>span]:grow
    [&>a>span]:pr-12
  ">
    <router-link class="
      btn 
      btn-white 
      md:w-1/2
    " to="/company">
      <picture class="img-wrap pageFooter-link-bottom-img">
        <source type="image/webp" srcset="@/assets/img/page/pageFooterCompany.webp"/>
        <img src="@/assets/img/page/pageFooterCompany.jpg"  alt=""/>
      </picture>
      <span>会社案内</span>
    </router-link>
    <router-link class="
      btn 
      btn-white 
      md:w-1/2
    " to="/contact">
      <picture class="
        img-wrap
        pageFooter-link-bottom-img
      ">
        <source type="image/webp" srcset="@/assets/img/page/pageFooterContact.webp"/>
        <img src="@/assets/img/page/pageFooterContact.jpg"  alt=""/>
      </picture>
      <span>お問い合わせ</span>
    </router-link>
  </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
a {
  img, source {
    transition: .5s ease;
    transform: scale(1);
  }
  &:hover {
    img, source {
      transform: scale(1.1);
    }
  }
}
.pageFooter-link-top {
  @apply 
    flex
    flex-wrap
    md:flex-nowrap
    justify-start
    md:justify-center
    gap-[5vw]
    md:gap-5
  ;
  li {
    @apply 
      relative
      leading-none
      overflow-hidden
      text-white
      w-full
      md:w-full
    ;
    a {
      @apply
        after:text-[clamp(3.75rem,_1.25rem_+_3.91vw,_5rem)]
        after:absolute
        after:mix-blend-overlay
        after:-top-[14px]
        after:-left-[5px]
      ;
      picture {
        @apply
          block
          aspect-[1.618/1]
          relative
          before:z-10
        ;
      }
      span {
        @apply 
          absolute
          bottom-6
          left-6
          text-[clamp(1.125rem,_0.875rem_+_0.39vw,_1.25rem)]
        ;
      }
    }
  }
}
.pageFooter-link-bottom-img {
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  @apply
    h-[100px]
    aspect-[4/3]
    lg:aspect-[2/1]
    md:aspect-[4/3]
    sm:aspect-[2/1]
  ;
}
</style>