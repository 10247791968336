<template>
<div id="about" class="w-m-cont m-auto py-l-block leading-loose">
  <p class="">弊社（グランドプロジェクト株式会社）が運営している、健康管理事業は、あなたの人生を“健康”の側面から豊かにするための事業です。<br>
    <br>
    具体的には、</p>
    <ul class="ul-auto ml-4">
      <li>ゴルフコースのご案内</li>
      <li>健康な体づくりのサポート</li>
      <li>ゴルフの会員権の売却・販売</li>
    </ul>
    <p class="mb-10">までを一貫してご提案いたしております。</p>
    <div class="w-full aspect-video">
      <iframe class="object-contain object-center w-full h-full" src="https://www.youtube.com/embed/S9sS3K1qo2k?si=QBcm8krnmPQiT-eA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>