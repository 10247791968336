<template>
<main id="serviceAsset">
    <pageHead class="bg-[url('@/assets/img/page/serviceAsset/pagehead.jpg')]">
      <template #pageTitle>資産管理</template>
      <a class="pageHeadButton" href="#about">事業概要</a>
      <a class="pageHeadButton" href="#vision">私たちが目指す場所</a>
      <a class="pageHeadButton" href="#tech">20代でも始められる「凍結解凍覚醒技術」</a>
      <a class="pageHeadButton" href="#contact">介護の制度</a>
      <!--a class="pageHeadButton" href="#flow">サービス利用の流れ</!--a-->
    </pageHead>
    <theAbout></theAbout>
    <theVision class="bg-gy"></theVision>
    <theTech></theTech>
    <theContact class="bg-gy"></theContact>
    <!--theFlow></!--theFlow-->
    <pageFooter></pageFooter>
</main>
</template>
    
<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import theAbout from '@/components/page/serviceAsset/theAbout.vue'
import theVision from '@/components/page/serviceAsset/theVision.vue'
import theTech from '@/components/page/serviceAsset/theTech.vue'
import theContact from '@/components/page/serviceAsset/theContact.vue'
// import theFlow from '@/components/page/serviceAsset/theFlow.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
    components: {
        pageHead,
        theAbout,
        theVision,
        theTech,
        theContact,
        // theFlow,
        pageFooter,
    },
}
</script>
    
<style lang="scss">
</style>