<template>
<div class="pageHead bg-cover bg-center text-white z-50 layer-bl-50 before:-z-10">
  <div class="pageHeadInner">
    <div class="pageHeadTitle">
      <h1 class=""><slot name="pageTitle"></slot></h1>
      <ul class="flex flex-wrap gap-5">
        <li class="flex items-center gap-5
        after:content-['']
        after:block
        after:border-l-gd
        after:border-y-transparent
        after:border-y-[5px]
        after:border-l-8"><router-link to="/">ホーム</router-link></li>
        <li><slot name="pageTitle"></slot></li>
      </ul>
    </div>
    <div class="pageHeadNav">
      <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.pageHead {
  .pageHeadInner {
    @apply
      w-f-cont
      m-auto
      flex
      flex-wrap
      items-end
    ;
    .pageHeadTitle {
      @apply
        w-full
        md:w-1/2
        flex
        flex-col
        pt-40
        pb-24
        md:py-60
      ;
      h1 {
        @apply
          font-serif
          text-[clamp(2rem,_3.13vw,_3rem)]
          tracking-widest
          mb-60-80
        ;
      }
    }
  }
}
</style>

<style lang="scss">
.pageHeadNav {
  @apply
    w-full
    md:w-1/2
    flex
    flex-wrap
    items-end
    justify-end
    gap-[5vw]
    md:gap-9
    mb-60-80
  ;
  a {
    @apply
      leading-[40px]
      md:leading-[56px]
      text-sm
      md:text-base
      px-3
      border-y
      border-y-white
      relative
      top-0
      transition-all
      flex
      items-center
      gap-[2.5vw]
      md:gap-5
      after:content-['']
      after:block
      after:border-t-gd
      after:border-x-transparent
      after:border-x-[5px]
      after:md:border-x-[7px]
      after:border-t-[9px]
      after:md:border-t-[12px]
      hover:top-2
    ;
  }
}
</style>