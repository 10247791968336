<template>
<div class="top-block top-block-wh">
  <span class="top-block-deco">Media</span>
  <section class="max-w-[1200px] w-[80%] mx-auto mb-[clamp(3rem,_-3rem_+_10vw,_6rem)]">
    <h2 class="w-f-cont m-auto text-center">ゴルフ会員権に関する情報を発信中</h2>
    <div class="flex gap-[1.75rem] flex-wrap">
        <div class="w-full md:w-[calc(33.33%_-_3.5rem_/_3)]" v-for="post in mediaPosts" :key="post.id">
            <a v-bind:href="post.link" class="hover:transform hover:duration-1000 hover:scale-150" target="_blank">
                <!-- アイキャッチ -->
                <img class="w-full" v-bind:src="post._embedded['wp:featuredmedia'][0].source_url" alt="">
                <!-- タイトル -->
                <p class="post-title mt-[1.75rem] font-bold">{{ post.title.rendered }}</p>
            </a>
        </div>
    </div>
    <div class="flex justify-end mt-16">
      <a class="block w-60 text-lg font-bold text-center py-4 pr-4 transition relative bg-bl text-white" href="https://blog.grandproject.co.jp" target="_blank">詳しく見る</a>
    </div>
    <!-- エラーの場合 -->
    <p>{{error}}</p>
  </section>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
      return {
          mediaPosts: [],
      }
  },
  mounted() {
      axios.get('https://blog.grandproject.co.jp/index.php?rest_route=/wp/v2/posts&per_page=3&_embed=wp:featuredmedia')
      .then(function(response){
          this.mediaPosts = response.data;
          //デバッグ用にconsoleに出力
          console.log(response.data)
      }.bind(this))
      .catch(function(error){
          console.log(error)
      })
  },
}
</script>

<style lang="scss" scoped>
@use "sass:math";

.service-box {
  @apply
    relative
    w-f-cont
    md:w-full
    mx-auto
    flex
    flex-col
    md:flex-row
    items-center
  ;
  &:nth-of-type(even) {
    @apply md:flex-row-reverse;
  }
  &:not(:last-of-type) {
    @apply mb-[clamp(6.25rem,_-1.25rem_+_11.72vw,_10rem)]
  }
  picture {
    @apply
      w-[90vw]
      overflow-hidden
      md:w-[calc(680_/_1680_*_100vw)]
      mb-32-40
      md:mb-0
      aspect-[1.618/1]
      md:aspect-[3/4]
      shadow-2xl
      relative
      left-0
    ;
    &:before {
    }
    &:after {
      @apply
        text-8xl
        sm:text-9xl
        lg:text-[clamp(12.5rem,_7.5rem_+_7.81vw,_15rem)]
        font-serif mix-blend-overlay text-white absolute leading-none z-10 
        -top-5
        sm:-top-8
        lg:-top-12
        -left-2
        sm:-left-3
        lg:-left-7
      ;
    }
  }
  section {
    @apply
      md:w-[calc((1680_-_680)_/_1680_*_100vw)]
      md:px-[calc(160_/_1680_*_100vw)]
    ;
    h3 {
      font-size: clamp(1.75rem, 0.25rem + 2.34vw, 2.5rem);
      @apply font-serif mb-8;
    }
    p {
      font-size: clamp(1rem, 0.75rem + 0.39vw, 1.125rem);
      @apply
        mb-32-40
        md:mb-20
      ;
    }
    .service-section-bottom {
      @apply flex items-center flex-wrap gap-x-5 gap-y-10 justify-end;
    }
  }
}

.service-box-sub {
  @apply sm:flex gap-6 items-center md:block;
  picture {
    @apply block aspect-[1.618/1] shadow-2xl mb-6
      sm:w-[60%] md:w-full
    ;
  }
  h3 {
    font-size: clamp(1.25rem, 0.75rem + 0.78vw, 1.5rem);
    @apply font-serif mx-4
      sm:w-[40%] md:w-full
    ;
  }
}
</style>