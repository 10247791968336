<template>
<div id="philosophy" class="bg-gy py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">理念</h2>
    <div class="w-m-cont m-auto">
      <p class="
        mb-60-80
        font-serif
        flex
        flex-wrap
        items-center
        justify-center
        text-[2rem]
        sm:text-[clamp(2.5rem,_3.91vw,_3.75rem)]
      ">
        <span>お客様の</span>
        <span>100年に寄り添う</span>
      </p>
      <p>平均寿命が伸び続け、人生100年時代に突入したといわれる現代では従来の人生設計は当てはまらず、正解は誰にも見つけることができない状態です。
        <br>だからこそ、逆に考えると自由に様々なことにチャレンジできるとも捉えられます。
        <br>
        <br>
        そこでグランドプロジェクトから提案するのが、人生100年時代に合わせたプランニングです。
        <br>
        年齢制限なく、ゴルフを楽しんでいただくことで若年性含む認知症の予防。20代から数十年後を見越した長期的な資産形成。必要に合わせた介護サポートの提供を、「お客様の人生に寄り添う」という理念に沿って一貫して行います。</p>
    </div>
  </section>
</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>