<template>
<div class="
  top-block
  bg-[url('@/assets/img/top/visionBg.jpg')]
  bg-cover
  bg-center
  layer-bl-70
  before:-z-10
">
  <span class="
    top-block-deco 
    whitespace-nowrap 
    text-white 
    mix-blend-overlay 
    rotate-90 
    origin-bottom-left 
    -left-1
    sm:-left-2
    md:-left-3
    -top-[1em]
    sm:-top-[1.05em]
    md:-top-[1.15em]
  ">Your Life and Us</span>
  <section class="
    lg:flex
    flex-wrap
    w-f-cont
    mx-auto
    mb-20
  ">
    <h2 class="
      lg:w-1/2
      text-white
    ">100年時代における
      <br>
      <span class="flex gap-y-2 flex-wrap">
        <span>お客様と</span>
        <span>グランドプロジェクト</span>
      </span>
    </h2>
    <p class="
      lg:w-1/2
      text-white
    ">平均寿命が伸び続け、人生100年時代に突入したといわれる現代では従来の人生設計は当てはまらず、正解は誰にも見つけることができない状態です。
      <br>
      だからこそ、逆に考えると自由に様々なことにチャレンジできるとも捉えられます。
      <br>
      <br>
      そこでグランドプロジェクトから提案するのが、人生100年時代に合わせたプランニングです。
      <br>
      年齢制限なく、ゴルフを楽しんでいただくことで若年性含む認知症の予防。20代から数十年後を見越した長期的な資産形成。必要に合わせた介護サポートの提供を、「お客様の人生に寄り添う」という理念に沿って一貫して行います。</p>
  </section>
  <div class="w-f-cont bg-white aspect-video m-auto p-[5vw] lg:p-60-80">
    <picture>
      <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/vision01_sp.webp"/>
      <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/vision01_sp.jpg"/>
      <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/vision01.webp 1x, @/assets/img/top/vision01@2x.webp 2x"/><img src="@/assets/img/top/vision01.jpg" srcset="@/assets/img/top/vision01.jpg 1x, @/assets/img/top/vision01@2x.jpg 2x" alt=""/>
    </picture>
  </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>