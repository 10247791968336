<template>
<div class="
  bg-[url('@/assets/img/top/companyImageBg.jpg')]
  bg-cover
  bg-center
  aspect-[1.618/1]
  md:aspect-[1680/720]
  overflow-hidden
  flex
  items-center
  justify-center
  layer-bl-70
  before:-z-10
">
  <img class="
    absolute
    mix-blend-overlay
    drop-shadow-2xl
    h-[105%]
    -left-5
  " src="@/assets/img/top/companyImageText.svg">
  <p class="
    font-serif
    text-center
    text-[clamp(1.5rem,_-0.5rem_+_3.13vw,_2.5rem)]
    text-white
  ">社員一同、<br>
    <span class="
      flex
      flex-wrap
      justify-center
    ">
      <span>皆様の幸せに寄与できるよう</span>
      <span>邁進いたします。</span>
    </span>
  </p>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>