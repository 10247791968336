<template>
<main id="serviceHealth">
    <pageHead class="bg-[url('@/assets/img/page/serviceHealth/pagehead.jpg')]">
      <template #pageTitle>健康管理</template>
      <a class="pageHeadButton" href="#about">事業概要</a>
      <a class="pageHeadButton" href="#vision">私たちが目指す場所</a>
      <a class="pageHeadButton" href="#recommendStock">弊社で会員権在庫のあるゴルフ場</a>
      <a class="pageHeadButton" href="#benefit">ゴルフから得られる効果</a>
      <a class="pageHeadButton" href="#flow">サービス利用の流れ</a>
    </pageHead>
    <theAbout></theAbout>
    <theVision></theVision>
    <theRecommendStock></theRecommendStock>
    <theBenefit></theBenefit>
    <theFlow></theFlow>
    <theSns></theSns>
    <pageFooter></pageFooter>
</main>
</template>
    
<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import theAbout from '@/components/page/serviceHealth/theAbout.vue'
import theVision from '@/components/page/serviceHealth/theVision.vue'
import theRecommendStock from '@/components/page/serviceHealth/theRecommendStock.vue'
import theBenefit from '@/components/page/serviceHealth/theBenefit.vue'
import theFlow from '@/components/page/serviceHealth/theFlow.vue'
import theSns from '@/components/top/theSns.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
    components: {
        pageHead,
        theAbout,
        theVision,
        theRecommendStock,
        theBenefit,
        theFlow,
        theSns,
        pageFooter,
    },
    mounted () {
    }
}
</script>
    
<style lang="scss">
</style>