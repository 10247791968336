<template>
<main id="serviceCare">
    <pageHead class="
        bg-[url('@/assets/img/page/serviceCare/pagehead_sp.jpg')]
        md:bg-[url('@/assets/img/page/serviceCare/pagehead.jpg')]
    ">
      <template #pageTitle>介護管理</template>
      <a class="pageHeadButton" href="#operation">運営</a>
      <a class="pageHeadButton" href="#about">事業概要</a>
      <a class="pageHeadButton" href="#vision">私たちが目指す場所</a>
      <a class="pageHeadButton" href="#care">多様化する“介護”</a>
      <a class="pageHeadButton" href="#system">介護の制度</a>
      <!--a class="pageHeadButton" href="#flow">介護サービス利用の流れ</!--a-->
      <a class="pageHeadButton" href="#feature">グランドプロジェクトの介護の特徴</a>
    </pageHead>
    <theOperation></theOperation>
    <theAbout></theAbout>
    <theVision></theVision>
    <theCare></theCare>
    <theSystem></theSystem>
    <!--theFlow></!--theFlow-->
    <theFeature></theFeature>
    <pageFooter></pageFooter>
</main>
</template>
    
<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import theOperation from '@/components/page/serviceCare/theOperation.vue'
import theAbout from '@/components/page/serviceCare/theAbout.vue'
import theVision from '@/components/page/serviceCare/theVision.vue'
import theCare from '@/components/page/serviceCare/theCare.vue'
import theSystem from '@/components/page/serviceCare/theSystem.vue'
// import theFlow from '@/components/page/serviceCare/theFlow.vue'
import theFeature from '@/components/page/serviceCare/theFeature.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
    components: {
        pageHead,
        theOperation,
        theAbout,
        theVision,
        theCare,
        theSystem,
        // theFlow,
        theFeature,
        pageFooter,
    },
}
</script>
    
<style lang="scss">

</style>