<template>
<div class="top-block top-block-wh">
  <span class="top-block-deco">Services</span>
  <section class="mb-[clamp(6.25rem,_-6.25rem_+_19.53vw,_12.5rem)]">
    <h2 class="w-f-cont m-auto">運営事業</h2>
    <div class="service-box">
      <span class="service-box-num"></span>
      <picture class="layer-bl-30 img-wrap after:content-['01']">
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service01_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service01_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service01.webp 1x, @/assets/img/top/service01@2x.webp 2x"/><img src="@/assets/img/top/service01.jpg" srcset="@/assets/img/top/service01.jpg 1x, @/assets/img/top/service01@2x.jpg 2x" alt=""/>
      </picture>
      <section class="">
        <h3>健康管理</h3>

        <div class="w-full aspect-video mb-8">
          <iframe class="object-contain object-center w-full h-full" src="https://www.youtube.com/embed/S9sS3K1qo2k?si=QBcm8krnmPQiT-eA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        <p>人生を“健康”の側面から豊かにすることを目指しています。
          <br/>
          認知症が増加傾向にある日本において、有効となるのがゴルフだと我々グランドプロジェクト株式会社は考えております。
          <br/>
          お客様に、楽しく魅力的なスポーツであるゴルフを楽しむことで認知症予防につなげていただき、優雅な人生の一助になること。そしてその全ての魅力をあなたに伝えることが私たちの使命であり、想いです。
        </p>
        <div class="service-section-bottom">
          <router-link class="btn btn-bl" to="/health">詳しく見る</router-link>
        </div>
      </section>
    </div>
    <div class="service-box">
      <picture class="layer-bl-30 img-wrap after:content-['02']">
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service02_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service02_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service02.webp 1x, @/assets/img/top/service02@2x.webp 2x"/><img src="@/assets/img/top/service02.jpg" srcset="@/assets/img/top/service02.jpg 1x, @/assets/img/top/service02@2x.jpg 2x" alt=""/>
      </picture>
      <section class="">
        <h3>介護管理</h3>
        <p>
          <span class="block">団塊の世代が後期高齢者に達する2025年。</span>
          <span class="block">日本は人口のおよそ1/4が65歳以上という超高齢化社会を欧米に先駆けてむかえています。</span>
          <span class="block">そういった課題に対し、解決手段を提供するべく私たちは介護事業を行なっています。</span>
        </p>
        <div class="service-section-bottom">
          <router-link class="btn btn-bl" to="/care">詳しく見る</router-link>
        </div>
      </section>
    </div>
    <div class="service-box">
      <picture class="layer-bl-30 img-wrap after:content-['03']">
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service03_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service03_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service03.webp 1x, @/assets/img/top/service03@2x.webp 2x"/><img src="@/assets/img/top/service03.jpg" srcset="@/assets/img/top/service03.jpg 1x, @/assets/img/top/service03@2x.jpg 2x" alt=""/>
      </picture>
      <section class="">
        <h3>資産管理</h3>
        <p>お客様の大切な資産を守るため資産管理事業を行なっております。資産状況に沿った相続税、贈与税に特化した税理士、弁護士をご紹介いたします。
          <br/>
          また、資産形成についての国内での農業コーヒー豆栽培事業とタイ王国でのCBD医療大麻・クラトム栽培事業のご紹介も行なっております。
          <br/>
          現代は100年時代。年齢に関係なく、投資や資産形成に取り組む皆様を支えます。
        </p>
        <div class="service-section-bottom">
          <router-link class="btn btn-bl" to="/asset">詳しく見る</router-link>
        </div>
      </section>
    </div>
  </section>
  <section class="w-f-cont m-auto">
    <h2>その他の事業</h2>
    <div class="flex flex-col md:flex-row gap-[clamp(2.5rem,_-2.5rem_+_7.81vw,_5rem)]">
      <section class="service-box-sub">
        <picture class="img-wrap">
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service04_sp.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service04_sp.jpg"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service04.webp 1x, @/assets/img/top/service04@2x.webp 2x"/><img src="@/assets/img/top/service04.jpg" srcset="@/assets/img/top/service04.jpg 1x, @/assets/img/top/service04@2x.jpg 2x" alt=""/>
        </picture>
        <h3>農業事業</h3>
      </section>
      <section class="service-box-sub md:mt-[clamp(2.5rem,_-2.5rem_+_7.81vw,_5rem)]">
        <picture class="img-wrap">
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service05_sp.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service05_sp.jpg"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service05.webp 1x, @/assets/img/top/service05@2x.webp 2x"/><img src="@/assets/img/top/service05.jpg" srcset="@/assets/img/top/service05.jpg 1x, @/assets/img/top/service05@2x.jpg 2x" alt=""/>
        </picture>
        <h3>広告代理店事業</h3>
      </section>
      <section class="service-box-sub">
        <picture class="img-wrap">
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/service06_sp.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/service06_sp.jpg"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/service06.webp 1x, @/assets/img/top/service06@2x.webp 2x"/><img src="@/assets/img/top/service06.jpg" srcset="@/assets/img/top/service06.jpg 1x, @/assets/img/top/service06@2x.jpg 2x" alt=""/>
        </picture>
        <h3>ネット販売事業</h3>
      </section>
    </div>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
@use "sass:math";

.service-box {
  @apply
    relative
    w-f-cont
    md:w-full
    mx-auto
    flex
    flex-col
    md:flex-row
    items-center
  ;
  &:nth-of-type(even) {
    @apply md:flex-row-reverse;
  }
  &:not(:last-of-type) {
    @apply mb-[clamp(6.25rem,_-1.25rem_+_11.72vw,_10rem)]
  }
  picture {
    @apply
      w-[90vw]
      overflow-hidden
      md:w-[calc(680_/_1680_*_100vw)]
      mb-32-40
      md:mb-0
      aspect-[1.618/1]
      md:aspect-[3/4]
      shadow-2xl
      relative
      left-0
    ;
    &:before {
    }
    &:after {
      @apply
        text-8xl
        sm:text-9xl
        lg:text-[clamp(12.5rem,_7.5rem_+_7.81vw,_15rem)]
        font-serif mix-blend-overlay text-white absolute leading-none z-10 
        -top-5
        sm:-top-8
        lg:-top-12
        -left-2
        sm:-left-3
        lg:-left-7
      ;
    }
  }
  section {
    @apply
      md:w-[calc((1680_-_680)_/_1680_*_100vw)]
      md:px-[calc(160_/_1680_*_100vw)]
    ;
    h3 {
      font-size: clamp(1.75rem, 0.25rem + 2.34vw, 2.5rem);
      @apply font-serif mb-8;
    }
    p {
      font-size: clamp(1rem, 0.75rem + 0.39vw, 1.125rem);
      @apply
        mb-32-40
        md:mb-20
      ;
    }
    .service-section-bottom {
      @apply flex items-center flex-wrap gap-x-5 gap-y-10 justify-end;
    }
  }
}

.service-box-sub {
  @apply sm:flex gap-6 items-center md:block;
  picture {
    @apply block aspect-[1.618/1] shadow-2xl mb-6
      sm:w-[60%] md:w-full
    ;
  }
  h3 {
    font-size: clamp(1.25rem, 0.75rem + 0.78vw, 1.5rem);
    @apply font-serif mx-4
      sm:w-[40%] md:w-full
    ;
  }
}
</style>