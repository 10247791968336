<template>
<section id="about" class="py-l-block leading-loose w-f-cont m-auto">
  <h2 class="h2-auto">会社概要</h2>
  <companyTable class="w-m-cont m-auto" />
</section>
</template>

<script>
import companyTable from '@/components/page/company/companyTable.vue'

export default {
  components: {
    companyTable
  }
}
</script>

<style lang="scss" scoped>

</style>