<template>
<div id="contact" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto mb-60-80">
    <h3 class="h3-auto text-center">具体的なプランや数字が知りたい方へ</h3>
    <p class="w-m-cont mx-auto mb-60-80">利回りや投資プランについてお知りになりたい方は、一度グランドプロジェクトへお問い合わせください。
      <br>
      担当者より凍結覚醒解凍技術について詳細にご説明いたします。</p>
    <div class="w-f-cont m-auto flex justify-center
      [&>a]:leading-[100px]
      [&>a]:transition
      [&>a]:flex
      [&>a]:items-center
      [&>a]:max-w-1/2
      [&>a]:w-[500px]
      [&>a]:p-0
      [&>a>picture]:h-[100px]
      [&>a>picture]:aspect-[2/1]
      [&>a>span]:grow
      [&>a>span]:pr-12
    ">
      <router-link class="btn btn-bl" to="/contact">
        <picture class="img-wrap pageFooter-link-bottom-img">
          <source type="image/webp" srcset="@/assets/img/page/pageFooterContact.webp"/>
          <img src="@/assets/img/page/pageFooterContact.jpg"  alt=""/>
        </picture>
        <span>お問い合わせ</span>
      </router-link>
    </div>
  </section>
</div>
</template>

<script>
export default {
  components: {
    },
}
</script>

<style lang="scss" scoped>
a {
  img, source {
    transition: .5s ease;
    transform: scale(1);
  }
  &:hover {
    img, source {
      transform: scale(1.1);
    }
  }
}
.pageFooter-link-bottom-img {
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  @apply
    h-[100px]
    aspect-[4/3]
    lg:aspect-[2/1]
    md:aspect-[4/3]
    sm:aspect-[2/1]
  ;
}
</style>