<template>
<main id="access">
  <pageHead class="bg-[url('@/assets/img/page/access/pagehead.jpg')]">
    <template #pageTitle>アクセス</template>
  </pageHead>
  <theOffices />
  <theSns></theSns>
  <pageFooter />
</main>
</template>

<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import theOffices from '@/components/page/access/theOffices.vue'
import theSns from '@/components/top/theSns.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
  components: {
    pageHead,
    theOffices,
    theSns,
    pageFooter,
  }
}
</script>

<style lang="scss" scoped>

</style>