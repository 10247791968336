<template>
<div id="system" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto mb-60-80">
    <h2 class="h2-auto">介護の制度</h2>
    <p class="w-m-cont mx-auto mb-60-80">
      <span class="block">日本では、介護保険と呼ばれる、介護が必要な高齢者の支援や、介護する家族の負担軽減を目指した制度があります。</span>
      <span class="block">40歳以上になると、加入することが義務付けられる保険料を財源として賄われています。</span>
      <span class="block">要介護認定を受けると介護保険が適用され、原則1割の自己負担額で介護を利用することができます。</span>
      <span class="block">詳しくは、厚生労働省のサイトをご参考ください。</span>
    <a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/hukushi_kaigo/kaigo_koureisha/gaiyo/index.html" target="_blank">https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/hukushi_kaigo/kaigo_koureisha/gaiyo/index.html</a></p>
    <div class="miniNavigator">
      <p>負担軽減につながる制度、<br>活用していきましょう</p>
      <img src="@/assets/img/common/navigator01.png">
    </div>
  </section>
</div>
</template>

<script>
export default {
  components: {
    },
}
</script>

<style lang="scss" scoped>

</style>