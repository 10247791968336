<template>
<main id="home">
    <theFirstView/>
    <theGreetings/>
    <theVision/>
    <theService/>
    <theSns/>
    <theCompanyImage/>
    <theCompany/>
</main>
</template>
    
<script>
import theFirstView from '@/components/top/theFirstView.vue'
import theSns from '@/components/top/theSns.vue'
import theGreetings from '@/components/top/theGreetings.vue'
import theVision from '@/components/top/theVision.vue'
import theService from '@/components/top/theService.vue'
import theCompanyImage from '@/components/top/theCompanyImage.vue'
import theCompany from '@/components/top/theCompany.vue'

export default {
    name: 'HomeView',
    components: {
        theFirstView,
        theSns,
        theGreetings,
        theVision,
        theService,
        theCompanyImage,
        theCompany,
    },
    mounted() {},
}
</script>
    
<style lang="scss">
.top-block {
    padding: clamp(6.25rem, -6.25rem + 19.53vw, 12.5rem) 0;
    @apply overflow-hidden relative bg-white;
    .top-block-deco {
        @apply
            font-serif
            text-[clamp(5rem,_3.333rem_+_6.67vw,_6rem)]
            sm:text-9xl
            md:text-[clamp(10rem,_5rem_+_7.81vw,_12.5rem)]
            absolute
            leading-none
        ;
    }
    h2 {
        @apply font-serif tracking-wider text-[clamp(1.5rem,_0.5rem_+_1.56vw,_2rem)] mb-60-80;
    }
    p {
        @apply leading-loose;
    }
    &.top-block-gy {
        @apply bg-gy;
        .top-block-deco {
            @apply
                text-white
                -top-5
                sm:-top-7
                md:-top-10
                -right-2
                sm:-right-4
                md:-right-7
            ;
        }
    }
    &.top-block-wh {
        .top-block-deco {
            @apply
                text-gy
                -top-5
                sm:-top-7
                md:-top-10
                -right-2
                sm:-right-4
                md:-right-7
            ;
        }
    }
}
</style>