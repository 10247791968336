<template>
<main id="company">
  <pageHead class="bg-[url('@/assets/img/page/contact/pagehead.jpg')]">
    <template #pageTitle>お問い合わせ</template>
  </pageHead>
  <div id="form" class="py-l-block w-s-cont mx-auto">
  </div>
  <pageFooter></pageFooter>
</main>
</template>

<script>
import pageHead from '@/components/page/layout/pageHead.vue'
import pageFooter from '@/components/page/layout/pageFooter.vue'

export default {
  components: {
    pageHead,
    pageFooter,
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "23341457",
          formId: "551dc238-2f83-4bd0-a20f-dea642a1ae9e",
          target: "#form",
        });
      }
    });
  },
}
</script>

<style lang="scss" scoped>
.required {
  &:after {
    content: "必須";
    @apply ml-2 inline-block text-sm text-white bg-gd px-[2px];
  }
}
</style>