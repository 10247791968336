<template>
<div id="flow" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">サービス利用の流れ</h2>
    <section class="w-m-cont mx-auto mb-60-80">
      <h3 class="h3-auto">会員権を売却いただく場合</h3>
      <ol class="ol-flow
        [&_.head]:font-bold
        [&_.head]:mb-2
        [&_.head]:text-lg
      ">
        <li>
          <div>
            <p class="head">買取価格のご提示</p>
            <p>一度、弊社にて買取価格をご提示いたします。買取価格はゴルフ場によって異なりますので、3営業日以内に価格のご提示をいたします。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">契約成立</p>
            <p>弊社より提示した金額にご同意いただけましたら、契約成立となります。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">書類の提出</p>
            <p>売却に必要な書類を提出いただき、弊社で手続きを進める形となります。</p>
          </div>
        </li>
      </ol>
    </section>
    <section class="w-m-cont mx-auto">
      <h3 class="h3-auto">会員権を購入いただく場合</h3>
      <ol class="ol-flow
        [&_.head]:font-bold
        [&_.head]:mb-2
        [&_.head]:text-lg
      ">
        <li>
          <div>
            <p class="head">お問い合わせ</p>
            <p>一度、弊社お問い合わせフォームもしくは、こちらよりお電話ください。</p>
            <p><a class="flex gap-2 font-bold" href="tel:0358091665"><img src="@/assets/img/common/iconTelGd.svg"> 03-5809-1665</a></p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">ヒヤリング</p>
            <p>弊社の営業担当が、お客様のご要望や、目的からゴルフ場選びを総合的にサポートいたします。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">ご提案</p>
            <p>ヒヤリングより5営業日以内にご提案と見積もりを提出いたします。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">契約成立</p>
            <p>お客様にご同意いただきましたら契約成立となります。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">入会お申込み、入会審査</p>
            <p>入会いただく前に、お申込み、面接が必要となります。不安も多いかとは思いますが、担当が総合的にサポートしますので、不安点やご意見などございましたらお気軽にご相談ください。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">入会</p>
            <p class="mb-4">入会審査に通った場合は、以下書類が必要となります。</p>
            <ul class="ul-auto flex flex-wrap
              lg:[&>li]:w-1/2
              xl:[&>li]:w-1/3
            ">
              <li>内容証明</li>
              <li>住民票</li>
              <li>実印</li>
              <li>委任状</li>
              <li>名義書換え申請書</li>
              <li>紛失届</li>
              <li>代金完済証明書</li>
              <li>免書</li>
              <li>譲渡通知書</li>
              <li>写真</li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <p class="head">書類のお届け</p>
            <p>入会手続きが完了しましたら、弊社より会員証をお届けいたします。</p>
          </div>
        </li>
        <li>
          <div>
            <p class="head">入会完了</p>
          </div>
        </li>
      </ol>
    </section>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>