<template>
<div id="about" class="py-l-block leading-loose bg-gy">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">事業概要</h2>
    <div class="col2 w-f-cont mb-60-80">
      <picture>
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceCare/about01_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceCare/about01_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceCare/about01.webp 1x, @/assets/img/page/serviceCare/about01@2x.webp 2x"/>
        <img src="@/assets/img/page/serviceCare/about01.jpg" srcset="@/assets/img/page/serviceCare/about01.jpg 1x, @/assets/img/page/serviceCare/about01@2x.jpg 2x" alt=""/>
      </picture>
      <p>
        <span class="block">団塊の世代が後期高齢者に達する2025年。</span>
        <span class="block">日本は人口のおよそ1/4が65歳以上という超高齢化社会を欧米に先駆けてむかえています。</span>
        <span class="block">それに伴い高齢化率は約30％にまで上昇、少子化出生率の減少、社会保障費の確保困難になど、不安な要素は少なくありません。</span>
        <span class="block">そういった課題に対し、解決手段を提供するべく私たちは介護事業を行なっています。</span>
      </p>
    </div>
    <section class="mb-32-40 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">訪問介護</h4>
      <p>弊社より介護士をご自宅へ派遣いたします。ご家族の都合で介護施設に通うのが難しい方、家から出ることが難しい方も安心してご利用いただけます。</p>
    </section>
    <section class="mb-32-40 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">介護施設への通所</h4>
      <p>トレーニング、レクリエーション、他の利用者様とのコミュニケーションなどを通して、生活機能の向上を目指します。</p>
    </section>
    <section class="w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">老人ホーム</h4>
      <p>お客様の人生を豊かにするためにはどうしたらいいのか。お客様それぞれに必要な“介護”を常に考え、居心地の良い介護施設の運営を行います。</p>
    </section>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>