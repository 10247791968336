<template>
<div class="top-block top-block-gy">
  <span class="top-block-deco">Company</span>
  <section class="">
    <h2 class="w-m-cont m-auto">会社案内</h2>
    <companyTable/>
  </section>
</div>
</template>

<script>
import companyTable from '@/components/page/company/companyTable.vue'

export default {
  components: {
    companyTable,
  }
}
</script>

<style lang="scss" scoped>

</style>