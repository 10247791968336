<template>
<div id="tech" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto leading-relaxed">20代でも始められる「凍結解凍覚醒技術」</h2>
    <p class="mb-60-80">弊社で推奨している「凍結解凍覚醒技術-最高級アラビカ種ティピカ珈琲投資」についてご紹介します。</p>
    <section class="bg-gy px-[5vw] md:px-60-80 py-32-40 mb-60-80">
      <h4 class="h4-auto">珈琲の2050年問題</h4>
      <p class="mb-32-40">
        <span class="block">このページをお読みいただているあなたは2050年問題を知っていますか？</span>
        <span class="block">珈琲豆の生産地は、赤道を中心に25度、南緯25度の帯状に広がった地域で栽培されています。</span>
        <span class="block">そこは「珈琲ベルト」と呼ばれており、気候区分では、熱帯や亜熱帯に属する地域になります。</span>
        <span class="block">それを聞くと赤道を中心に広がっている地域のため、珈琲豆は暑さに強いイメージを持つでしょう。</span>
        <span class="block">しかしながら、実は珈琲豆の生産は標高が高く霜害を避ける地域を選ばないと生産できません。</span>
      </p>
      <div class="col2 mb-60-80">
        <picture>
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/tech01_sp.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/tech01_sp.jpg"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceAsset/tech01.webp 1x, @/assets/img/page/serviceAsset/tech01@2x.webp 2x"/>
          <img src="@/assets/img/page/serviceAsset/tech01.jpg" srcset="@/assets/img/page/serviceAsset/tech01.jpg 1x, @/assets/img/page/serviceAsset/tech01@2x.jpg 2x" alt=""/>
        </picture>
        <div>
          <p>
            <span class="block">その珈琲ベルト地域がここ数十年、地球温暖化により環境変化に伴って、世界で栽培される珈琲豆の6割を占めるアラビカ種が打撃を受けています。</span>
            <span class="block">アメリカの調査機関によるとアラビカ種の珈琲栽培に適した土地は、<b>2050年に現在の50%まで縮小</b>されると報告されています。</span>
            <span class="block">これを、「珈琲の2050年問題」と呼びます。</span>
          </p>
        </div>
      </div>
      <h4 class="h4-auto">珈琲の市場性</h4>
      <p class="">
        <span class="block">縮小される生産地と反比例して、珈琲の消費量は年々増しており、2017年時点で世界中で9,300億杯が飲まれている計算になります。</span>
        <span class="block">世界の人口が80億人と仮定しても<b>一人100杯以上</b>飲んでいる計算ですね。</span>
        <span class="block">それが2050年を過ぎると、気候変動によって病害虫の増加が起きる可能性が高いといわれており、実際にそうなった場合には、気軽においしい珈琲が飲むことは難しくなります。</span>
        <span class="block">そんな未来を危惧した、味の素、丸紅、ネスレ日本などの名だたる企業は鹿児島、沖縄などで<b>珈琲豆の育成</b>を進めています。</span>
        <br>
        <br></p>
    </section>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h3 class="h3-auto">高級珈琲について</h3>
      <p class="mb-32-40">
        <span class="block">原種ティピカ珈琲豆を原種として作られたパナマゲイシャ珈琲豆は世界でも非常に高額で取引される品種です。</span>
        <span class="block">（パナマゲイシャ珈琲豆は、エチオピアのゲイシャ村で生産される世界最高級珈琲であるティピカ珈琲樹果実を酵素発酵し、アフリカンベッド（日陰で木製ベッドを重ねて作成）作出されるのと同等の加工技術で生産されています。）</span>
      </p>
    </section>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h3 class="h3-auto">凍結覚醒技術について</h3>
      <p class="mb-32-40">
        <span class="block">国内企業、「凍結解凍覚醒技術株式会社」の田中氏が保有している特許権です。</span>
        <span class="block">これにより国内では生産が難しかった貴重な高級珈琲豆の早い段階での生産・収穫が可能となりました。</span>
        <span class="block">また、国内で生産することにより、国間での輸送時に使用する防腐処理材などを施す必要がないため完全なオーガニックな珈琲を飲むことができます。</span>
      </p>
    </section>
    <div class="mb-60-80 w-m-cont mx-auto md:pr-[calc(132_/_800_*_100%)] relative">
      <section class="">
        <h3 class="h3-auto">凍結覚醒技術を使うことによって…</h3>
        <p class="mb-32-40">今までは生産が難しかった国産で、防腐剤を使用せずに、通常より速く、かつ大量に、品質の高い珈琲豆の生産が可能です。</p>
      </section>
      <section class="">
        <h3 class="h3-auto">投資としての凍結覚醒解凍技術</h3>
        <p class="mb-32-40">
          <span class="block">私たちが取り扱っている凍結覚醒解凍技術は、珈琲豆の苗木をリース契約として出資いただく形式となっています。</span>
          <span class="block">輸入品が99%を占める珈琲国内市場ですが、この技術は大きなビジネスチャンスとなります。</span>
          <span class="block">既に、現時点で珈琲業界大手であるUCCへの販売と、国内外への販売も決定しています。</span>
        </p>
      </section>

      <div class="visionNavigator">
        <p>コーヒーの<br>新しい価値！</p>
        <picture>
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.png"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/common/navigator02.webp 1x, @/assets/img/common/navigator02@2x.webp 2x"/>
          <img src="@/assets/img/common/navigator02.png" srcset="@/assets/img/common/navigator02.png 1x, @/assets/img/common/navigator02@2x.png 2x" alt=""/>
        </picture>
      </div>
    </div>
    <div class="
      grid
      grid-cols-2
      grid-rows-[auto]
      gap-[2.5vw]
      sm:gap-8
      [&>*]:bg-gy
      [&>*]:p-32-40
      [&>*>*:not(h4)]:leading-loose
    ">
      <div class="col-span-2 xsm:col-span-1">
        <h4 class="h4-auto">販売相場</h4>
        <p>500g = ¥8,424</p>
      </div>
      <div class="col-span-2 xsm:col-span-1">
        <h4 class="h4-auto">販売価格</h4>
        <p>珈琲1杯 = ¥2,000</p>
      </div>
      <div class="col-span-2">
        <h4 class="h4-auto">リスク対策</h4>
        <ul class="ul-auto">
          <li>災害などのリスクに備えて、生産地を全国に分散しています。
            <br>
            生産地：宮崎、鹿児島、壱岐対馬、大分、神奈川、名古屋、浜松</li>
          <li>栽培箇所にリスク対策として、リース本数より20%の余剰分の栽培を実施します。</li>
          <li>ハウス栽培のため、通常の栽培と比べても災害リスクは限りなく低くなります。</li>
          <li>法的側面においても、顧問弁護士と契約していますのでご安心ください。</li>
        </ul>
      </div>
      <div class="col-span-2 md:col-span-1">
        <h4 class="h4-auto">保険</h4>
        <p>農業において最も恐れられるリスクが災害です。
          <br>
          万が一、地震や台風で被害が発生したとしても、リース料を滞りなくお支払いするために農林水産省が推奨する収入保険に加入しているため、災害による出資者の方へ被害が及ぶことはありません。
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <h4 class="h4-auto">ハウス栽培のエネルギーについて</h4>
        <p>農業において最も恐れられるリスクが災害です。
          <br>
          地震や台風による電力停止が発生した場合に備え、今後展開していくハウス栽培所では、太陽光パネルを用いた自家発電を取り入れます。
        </p>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>