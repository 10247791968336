<template>
<div id="feature" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">グランドプロジェクトの介護の特徴</h2>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">若い力</h4>
      <p>私たちの特徴は、20~30代の介護士が多いことです。<br>
        介護の業界は、ヘルパーも高齢化してきているのが現状ですが、若い世代と関わっていただくことがあなたの笑顔に繋がりますし、私たちも皆さんとお話しできるのを楽しみにしています！</p>
    </section>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">お客様1人1人との会話を楽しむ</h4>
      <p>楽しんで会話をすることは、脳を刺激して認知症の予防にも繋がります。</p>
    </section>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">心もこもった対応を</h4>
      <p>お客様のために、何をすべきかを常に考えています。</p>
    </section>
    <section class="mb-60-80 w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">誠意を持って</h4>
      <p>利用者様と近い距離だからこそ、誠意を持って対応いたします。</p>
    </section>
    <section class="w-m-cont mx-auto">
      <h4 class="h4-auto leading-none">それぞれの“介護”を</h4>
      <p>お客様の幸せのための、それぞれの“介護”を提供します。</p>
    </section>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>