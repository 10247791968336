<template>
<div id="offices" class="py-l-block leading-loose w-s-cont mx-auto">
  <div class="flex flex-wrap gap-[10%] mb-24">
    <div class="w-full lg:w-[40%]">
      <p class="font-bold mb-32-40 text-[clamp(1.125rem,_0.875rem_+_0.39vw,_1.25rem)]">
        グループ本社
        <br/>
        グランドプロジェクト株式会社
      </p>
      <p class="detail"><img src="@/assets/img/common/iconTelGd.svg" />
        <span>
          <a href="tel:0358091665">
            03-5809-1665
          </a>
        </span>
      </p>
      <p class="detail"><img src="@/assets/img/common/iconMapGd.svg" />
        <span>
          〒103-0004
          <br />
          東京都中央区東日本橋2-24-12 東日本橋槇町ビル6F
        </span>
      </p>
    </div>
    <iframe class="aspect-[3/2] w-full lg:w-1/2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.2908539625214!2d139.78428861525927!3d35.69445958019131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cfe34b7f38c8ddc!2z5p2x5pel5pys5qmL5qeH55S644OT44Or!5e0!3m2!1sja!2sjp!4v1668495989262!5m2!1sja!2sjp" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
p.detail {
  @apply flex items-start mb-16-20;
  img {
    @apply w-5 mr-4 relative top-[.275em];
  }
  span {
    @apply flex-1;
  }
}
</style>