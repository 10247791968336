<script>
export default {
  data() {
    return {
      windowWidth: "",
      // menuOpenの真偽値はビューポート幅によるグローバルメニュー展開（v-show）と、
      // ハンバーガーメニュークリックによるグローバルメニュー展開（openクラスの付与）の両方で使用
      menuOpen: "",
      // buttonShowの真偽値はビューポート幅によるハンバーガーメニュー表示（v-show）と、
      // モバイル用のグローバルメニュー（sp-menuクラスの付与）の両方で使用
      buttonShow: "",
    };
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
      // 768px以上でtrue → menuを展開
      this.menuOpen = this.windowWidth >= 1100;
      // 768px未満でtrue → menuボタンを表示
      this.buttonShow = this.windowWidth < 1100;
    },
  },
  created() {
    // リサイズ時のイベントハンドラを登録
    window.addEventListener("resize", this.getWindowWidth);
  },
  beforeUnmount() {
    // リサイズ時のイベントハンドラを解除
    window.removeEventListener("resize", this.getWindowWidth);
  },
  mounted: function () {
    // ウィンドウ幅の初期値を設定
    this.getWindowWidth();
  },
};
</script>

<template>
  <header class="pl-5 flex justify-between">
    <h1
      class="font-serif z-[99] absolute top-0 left-5 lg:static"
      id="headerLogo"
    >
      <router-link
        class="text-white transition-all leading-[60px] md:leading-[80px] lg:leading-[100px]"
        to="/"
      >
        Grand Project Co., Ltd
      </router-link>
    </h1>
    <button
      v-show="buttonShow"
      class="flex justify-center items-center h-[60px] aspect-square bg-bl sm:h-[80px] fixed top-0 right-0 z-[9999]"
      @click="menuOpen = !menuOpen"
    >
      <span
        :class="{ on: menuOpen }"
        class="block w-8 h-[2px] bg-white relative transition-all duration-300 ease-out [&.on]:bg-transparent before:block before:w-8 before:h-[2px] before:bg-white before:absolute before:-top-3 before:transition-all before:duration-300 before:ease-out [&.on]:before:-rotate-45 [&.on]:before:top-0 after:block after:w-8 after:h-[2px] after:bg-white after:absolute after:-bottom-3 after:transition-all after:duration-300 after:ease-out [&.on]:after:rotate-45 [&.on]:after:bottom-0"
      ></span>
    </button>
    <transition name="hideHeader">
      <nav
        v-show="menuOpen"
        v-if="buttonShow"
        class="fixed top-0 right-0 w-[100vw] h-full bg-white pt-[100px] px-[5vw] pb-[40px] z-[999] overflow-y-scroll sm:pt-[120px] sm:px-[10vw] md:w-[50vw] md:px-[40px] before:md:content-[''] before:w-[50vw] before:h-full before:absolute before:top-0 before:-left-[100%] before:-z-50 before:bg-black/50"
      >
        <h1 class="font-serif z-[99] absolute top-0 md:left-10" id="headerLogo">
          <router-link
            @click="menuOpen = !menuOpen"
            class="text-bl transition-all leading-[60px] md:leading-[80px] lg:leading-[100px]"
            to="/"
          >
            Grand Project Co., Ltd
          </router-link>
        </h1>
        <ul class="flex flex-col">
          <li class="text-bl pb-4 md: pb-8 border-b border-bl">
            運営事業
            <ul
              class="mt-4 lg:mt-8 flex flex-wrap gap-5 text-white [&>li]:w-[calc(50%_-_10px)] [&>li]:relative [&>li]:leading-none [&>li]:overflow-hidden [&>li>a]:group [&>li>a]:after:text-[clamp(3.75rem,_1.25rem_+_3.91vw,_5rem)] [&>li>a]:after:absolute [&>li>a]:after:mix-blend-overlay [&>li>a]:after:-top-[14px] [&>li>a]:after:-left-[5px] [&>li>a>picture]:img-wrap [&>li>a>picture]:block [&>li>a>picture]:aspect-[1.618/1] [&>li>a>picture]:relative [&>li>a>picture]:z-0 [&>li>a>picture]:before:z-10 [&>li>a>span]:absolute [&>li>a>span]:bottom-6 [&>li>a>span]:left-6 [&>li>a>span]:text-[clamp(1.125rem,_0.875rem_+_0.39vw,_1.25rem)]"
            >
              <li>
                <router-link
                  @click="menuOpen = !menuOpen"
                  class="font-serif after:content-['01']"
                  to="/health"
                >
                  <picture class="img-wrap layer-bl-70">
                    <source
                      type="image/webp"
                      srcset="@/assets/img/top/service01.webp"
                    />
                    <img src="@/assets/img/top/service01.jpg" alt="" />
                  </picture>
                  <span>健康管理</span>
                </router-link>
              </li>
              <li>
                <router-link
                  @click="menuOpen = !menuOpen"
                  class="font-serif after:content-['02']"
                  to="/care"
                >
                  <picture class="img-wrap layer-bl-70">
                    <source
                      type="image/webp"
                      srcset="@/assets/img/top/service02.webp"
                    />
                    <img src="@/assets/img/top/service02.jpg" alt="" />
                  </picture>
                  <span>介護管理</span>
                </router-link>
              </li>
              <li>
                <router-link
                  @click="menuOpen = !menuOpen"
                  class="font-serif after:content-['03']"
                  to="/asset"
                >
                  <picture class="img-wrap layer-bl-70">
                    <source
                      type="image/webp"
                      srcset="@/assets/img/top/service03.webp"
                    />
                    <img src="@/assets/img/top/service03.jpg" alt="" />
                  </picture>
                  <span>資産管理</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!--li class="text-bl
                    pb-4
                    md:pb-8
                    border-b border-bl
                "><span class="leading-[80px]">ゴルフ場会員権</span>
                    <ul class="
                        text-white
                        [&>li:not(:last-of-type)]:mb-3
                        [&>li>a]:px-5
                        [&>li>a]:py-6
                        [&>li>a]:bg-bl
                        [&>li>a]:text-white
                        [&>li>a]:flex
                        [&>li>a]:gap-5
                        [&>li>a]:items-center
                        [&>li>a]:before:content-['']
                        [&>li>a]:before:w-2
                        [&>li>a]:before:aspect-square
                        [&>li>a]:before:rounded-full
                        [&>li>a]:before:bg-white
                    ">
                        <li>
                            <router-link @click="menuOpen = !menuOpen" class="" to="/golf-recommend">おすすめのゴルフ場</router-link>
                        </li>
                        <li>
                            <router-link @click="menuOpen = !menuOpen" class="" to="/golf-stock">会員権の在庫</router-link>
                        </li>
                    </ul>
                </!--li-->
          <li class="border-b border-bl">
            <router-link
              @click="menuOpen = !menuOpen"
              class="leading-[60px] md:leading-[80px] text-bl transition-all duration-300 block pl-0 hover:bg-bl hover:text-white hover:pl-8"
              to="/company"
            >
              会社情報
            </router-link>
          </li>
          <li class="border-b border-bl">
            <router-link
              @click="menuOpen = !menuOpen"
              class="leading-[60px] md:leading-[80px] text-bl transition-all duration-300 block pl-0 hover:bg-bl hover:text-white hover:pl-8"
              to="/access"
            >
              アクセス
            </router-link>
          </li>
          <li class="border-b border-bl">
            <a 
              href="https://blog.grandproject.co.jp" 
              target="_blank"
              class="leading-[60px] md:leading-[80px] text-bl transition-all duration-300 block pl-0 hover:bg-bl hover:text-white hover:pl-8"
            >
              ゴルフ会員権メディア
            </a>
          </li>
          <li class="border-b border-bl">
            <a 
              href="https://twitter.com/grandp_golf" 
              target="_blank"
              class="leading-[60px] md:leading-[80px] text-bl transition-all duration-300 block pl-0 hover:bg-bl hover:text-white hover:pl-8"
            >
              Twitter
            </a>
          </li>
          <li class="contact mt-8">
            <router-link
              @click="menuOpen = !menuOpen"
              class="text-white transition-all duration-300 leading-[60px] font-bold flex gap-3 items-center px-5 bg-gd hover:bg-bl"
              to="/contact"
            >
              <img class="h-4" src="@/assets/img/common/iconMailWh.svg" />
              メールで問い合わせる</router-link
            >
          </li>
        </ul>
      </nav>
      <nav v-show="menuOpen" v-else class="l-menu pr-5">
        <ul class="flex items-center [&>li]:leading-[100px] text-sm">
          <li
            class="parent px-4 text-white transition-all duration-300 cursor-pointer group hover:bg-bl hover:text-white"
          >
            運営事業
            <div
              class="child invisible transition duration-300 opacity-0 group-hover:visible group-hover:opacity-100 absolute w-screen left-0 bg-bl py-32-40"
            >
              <ul
                class="flex justify-center gap-5 [&>li]:relative [&>li]:leading-none [&>li]:overflow-hidden [&>li>a]:group [&>li>a]:after:text-[clamp(3.75rem,_1.25rem_+_3.91vw,_5rem)] [&>li>a]:after:absolute [&>li>a]:after:mix-blend-overlay [&>li>a]:after:-top-[14px] [&>li>a]:after:-left-[5px] [&>li>a>picture]:img-wrap [&>li>a>picture]:block [&>li>a>picture]:aspect-[1.618/1] [&>li>a>picture]:relative [&>li>a>picture]:z-0 [&>li>a>picture]:before:z-10 [&>li>a>span]:absolute [&>li>a>span]:bottom-6 [&>li>a>span]:left-6 [&>li>a>span]:text-[clamp(1.125rem,_0.875rem_+_0.39vw,_1.25rem)]"
              >
                <li class="max-w-xs">
                  <router-link
                    class="font-serif after:content-['01']"
                    to="/health"
                  >
                    <picture class="img-wrap layer-bl-70">
                      <source
                        type="image/webp"
                        srcset="@/assets/img/top/service01.webp"
                      />
                      <img src="@/assets/img/top/service01.jpg" alt="" />
                    </picture>
                    <span>健康管理</span>
                  </router-link>
                </li>
                <li class="max-w-xs">
                  <router-link
                    class="font-serif after:content-['02']"
                    to="/care"
                  >
                    <picture class="img-wrap layer-bl-70">
                      <source
                        type="image/webp"
                        srcset="@/assets/img/top/service02.webp"
                      />
                      <img src="@/assets/img/top/service02.jpg" alt="" />
                    </picture>
                    <span>介護管理</span>
                  </router-link>
                </li>
                <li class="max-w-xs">
                  <router-link
                    class="font-serif after:content-['03']"
                    to="/asset"
                  >
                    <picture class="img-wrap layer-bl-70">
                      <source
                        type="image/webp"
                        srcset="@/assets/img/top/service03.webp"
                      />
                      <img src="@/assets/img/top/service03.jpg" alt="" />
                    </picture>
                    <span>資産管理</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <!--li
            class="parent px-8 text-white transition-all duration-300 cursor-pointer group hover:bg-bl hover:text-white"
          >
            ゴルフ場会員権
            <div
              class="child invisible transition duration-300 opacity-0 group-hover:visible group-hover:opacity-100 absolute w-screen left-0 bg-bl py-32-40"
            >
              <ul
                class="flex justify-center gap-5 [&>li]:leading-none [&>li]:min-w-[240px] [&>li>a]:group [&>li>a]:text-base"
              >
                <li>
                  <router-link class="btn btn-white" to="/golf-recommend"
                    >おすすめのゴルフ場</router-link
                  >
                </li>
                <li>
                  <router-link class="btn btn-white" to="/golf-stock"
                    >会員権の在庫</router-link
                  >
                </li>
              </ul>
            </div>
          </!--li-->
          <li>
            <router-link
              class="text-white transition-all duration-300 block px-4 hover:bg-bl hover:text-white"
              to="/company"
            >
              会社情報
            </router-link>
          </li>
          <li class="mr-5">
            <router-link
              class="text-white transition-all duration-300 block px-4 hover:bg-bl hover:text-white"
              to="/access"
            >
              アクセス
            </router-link>
          </li>
          <li class="mr-5">
            <a
              class="text-white transition-all duration-300 block px-4 hover:bg-bl hover:text-white"
              href="https://blog.grandproject.co.jp"
              target="_blank"
            >
              ゴルフ会員権メディア
            </a>
          </li>
          <li class="mr-5">
            <a
              class="text-white transition-all duration-300 flex px-4 hover:bg-bl hover:text-white"
              href="https://twitter.com/grandp_golf"
              target="_blank"
            >
              Twitter
            </a>
          </li>
          <li class="contact">
            <router-link
              class="text-white transition-all duration-300 leading-[60px] font-bold flex gap-3 items-center px-5 bg-gd hover:bg-bl"
              to="/contact"
            >
              <img class="h-4" src="@/assets/img/common/iconMailWh.svg" />
              メールで問い合わせる</router-link
            >
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<style lang="scss" scoped>
header {
  #headerLogo {
    a {
      font-size: clamp(1.25rem, 0.75rem + 0.78vw, 1.5rem);
    }
  }
  nav {
    > ul {
      > li {
        ul {
        }
      }
    }
  }
  &.on {
    h1 {
      a {
        @apply lg:text-bl;
      }
    }
    nav.l-menu {
      > ul {
        > li {
          &:not(.contact) {
            @apply text-bl;
            &:hover {
              @apply text-white;
            }
            > a {
              @apply text-bl;
              &:hover {
                @apply text-white;
              }
            }
          }
          .child {
            ul {
              li {
                @apply text-white;
              }
            }
          }
        }
      }
    }
  }
}

.hideHeader-enter-active,
.hideHeader-leave-active {
  transition: opacity 0.5s;
  opacity: 1;
}
.hideHeader-enter, .hideHeader-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity 0.5s;
  opacity: 0;
}

a {
  img,
  source {
    transition: 0.5s ease;
    transform: scale(1);
  }
  &:hover {
    img,
    source {
      transform: scale(1.1);
    }
  }
}
</style>
