<template>
  <div>
    <p class="mb-20">
      日本は、バブル経済崩壊後より長きにわたる最低の生活水準を記録し続けてきました。
      <br />その背景には、東日本大震災を始めとする未曾有の大災害がありましたが、そんな出来事を「絆」という日本人独自の力で乗り越え、近年は復活の一歩を歩み始めております。
      <br />
      <br />しかし私たちの日本は、高齢化、国債の発行額、年金受給率の増加など、世界基準で考えても決して安定しているとは言えません。
      <br />
      <br />グランドプロジェクト株式会社は、そのような時代を安心して、健康、優雅な生活を送りたいと感じる皆様を、様々な面からサポートさせていただきたいと考えております。
      <br />
      <br />近未来に訪れるであろう、皆様の幸せを糧にして社員一同、邁進し続けることをお約束します。
    </p>
    <div class="text-right font-serif">
      <!--p class="">CEO 岡田 浩之</!--p-->
      <p class="">
        <span class="mr-4">グループCEO 兼 代表取締役</span>
        <span class="text-[clamp(1.5rem,_0.5rem_+_1.56vw,_2rem)]"
          >岡田浩之</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
