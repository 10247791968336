<template>
<div id="vision" class="py-l-block leading-loose overflow-hidden">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">私たちが目指す場所</h2>
    <section class="w-m-cont mx-auto mb-60-80">
      <p class="mb-32-40">団塊の世代が後期高齢者に達する2025年。日本の人口のおよそ25%以上が65歳を超えるという<b>超高齢者化社会</b>を世界に先駆けて迎えています。<br>
          そんな中、私たちが目指すのは<b>「誰もが健康で長寿を全うできる社会の実現」</b>です。</p>
      <div class="bg-gy visionNavigatorWrap">
        <h4 class="h4-auto">団塊の世代とは？</h4>
        <p class="mb-32-40">戦後の第一次ベビーブーム期(1974~1949)に生まれた世代を団塊の世代と呼びます。<br>
          この3年間に生まれた人口は800万人に上ります。これは2017年~2019年の253万人と比べても3倍以上の数字です。</p>
        <h4 class="h4-auto">実際に起きた労働力の減少</h4>
        <p class="mb-32-40">実際に団塊の世代が60歳の定年を迎えた2007年は、労働力の大きな減少、技術や技能の継承など様々な課題が浮き彫りになりましたが、再雇用制度の活用により大きな問題には発展しませんでした。</p>
        <p>2025年に来るべき超高齢化社会において、私たちグランドプロジェクトが提供しているサービスが、2007年の再雇用制度と同じ課題解決に繋がると確信しています。</p>
        <div class="visionNavigator">
          <p class="bg-gy">誰もが輝ける<br>社会を目指します</p>
          <picture>
            <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.webp"/>
            <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.png"/>
            <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/common/navigator02.webp 1x, @/assets/img/common/navigator02@2x.webp 2x"/>
            <img src="@/assets/img/common/navigator02.png" srcset="@/assets/img/common/navigator02.png 1x, @/assets/img/common/navigator02@2x.png 2x" alt=""/>
          </picture>
        </div>
      </div>
    </section>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>