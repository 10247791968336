<template>
<footer class="
    bg-grad
    bg-cover
    bg-center
    pt-[clamp(6.25rem,_-6.25rem_+_19.53vw,_12.5rem)]
    md:py-[clamp(6.25rem,_-6.25rem_+_19.53vw,_12.5rem)]
">
    <div class="
        w-f-cont
        m-auto
        md:flex
        justify-between
        text-white
    ">
        <div>
            <p class="font-serif mb-60-80" id="footerLogo">
                <router-link class="text-white" to="/"> Grand Project Co., Ltd </router-link>
            </p>
            <div>
                <p class="mb-[1em] text-[clamp(1.125rem,_0.875rem_+_0.39vw,_1.25rem)]">グランドプロジェクト株式会社</p>
                <ul class="mb-[1em] leading-loose [&>li]:flex [&>li]:items-center [&>li]:gap-[1em]">
                    <li>
                        <img src="@/assets/img/common/iconTelWh.svg">
                        <a href="tel:0358091665">03-5809-1665</a>
                    </li>
                    <li>
                        <img src="@/assets/img/common/iconMailWh.svg">
                        <a href="mailto:info@grandproject.co.jp">info@grandproject.co.jp</a>
                    </li>
                    <li>
                        <img src="@/assets/img/common/iconMapWh.svg">
                        〒103-0004 東京都中央区東日本橋2-24-12 東日本橋槇町ビル6F
                    </li>
                </ul>
            </div>
            <iframe class="aspect-[1.618/1] w-full max-w-sm mb-60-80" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.2908539625214!2d139.78428861525927!3d35.69445958019131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cfe34b7f38c8ddc!2z5p2x5pel5pys5qmL5qeH55S644OT44Or!5e0!3m2!1sja!2sjp!4v1668495989262!5m2!1sja!2sjp" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <commonNav />
    </div>
    <p class="
        text-white
        w-f-cont
        m-auto
        font-serif
        text-center
        md:text-left
        leading-[100px]
        md:leading-[unset]
    ">©︎ Grand Project Co., Ltd</p>
</footer>
</template>
      
<script>
import commonNav from './commonNav'

export default {
    name: 'footerArea',
    components: {
        commonNav
    }
}
</script>
      
<style lang="scss">
@use 'sass:math';
footer {
    #footerLogo {
        font-size: clamp(1.25rem, 0.75rem + 0.78vw, 1.5rem);
    }
    nav {
        > ul {
            @apply leading-extra-loose;
            > li {
                > a {
                    @apply transition-all relative right-0;
                    &:before {
                        content: url('@/assets/img/common/arrow.svg');
                        top: calc(50% - 8px);
                        @apply transition-all
                            leading-none opacity-0
                            absolute -left-5;
                    }
                    &:hover {
                        @apply -right-5;
                        &:before {
                            content: url('@/assets/img/common/arrow.svg');
                            @apply opacity-100;
                        }
                    }
                }
                &.parent {
                    .child {
                        @apply ml-5;
                        ul {
                            li {
                                @apply flex gap-[1em] items-center;
                                &:before {
                                    content: "";
                                    @apply bg-white w-[10px] h-[2px];
                                }
                                a {
                                    @apply relative transition-all left-0;
                                    &:hover {
                                        @apply left-5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>