<template>
<div class="top-block top-block-gy">
  <span class="top-block-deco">Greetings</span>
  <div class="flex justify-between items-start w-f-cont mx-auto">
    <section class="w-f-cont m-auto">
      <h2>代表挨拶</h2>
      <greetingsDetail class="w-s-cont m-auto" />
    </section>
  </div>
</div>
</template>

<script>
import greetingsDetail from '@/components/page/company/greetingsDetail.vue'

export default {
  components: {
    greetingsDetail
  }
}
</script>

<style lang="scss" scoped>

</style>