<template>
<div id="about" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">事業概要</h2>
    <div class="col2 mb-60-80">
      <picture>
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/about01_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/about01_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceAsset/about01.webp 1x, @/assets/img/page/serviceAsset/about01@2x.webp 2x"/>
        <img src="@/assets/img/page/serviceAsset/about01.jpg" srcset="@/assets/img/page/serviceAsset/about01.jpg 1x, @/assets/img/page/serviceAsset/about01@2x.jpg 2x" alt=""/>
      </picture>
      <div>
        <p class="mb-32-40">
          <span class="block">私たちは、お客様の大切な資産を守るため資産管理事業を行なっております。</span>
          <span class="block">資産状況に沿った相続税、贈与税に特化した税理士、弁護士をご紹介いたします。</span>
        </p>
        <div class="bg-gy p-32-40">
          <p class="mb-16-20 font-bold">顧問税理士</p>
          <picture class="block mb-16-20 max-w-[220px]">
            <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/aboutBricks&UKLogo_sp.webp"/>
            <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceAsset/aboutBricks&UKLogo_sp.png"/>
            <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceAsset/aboutBricks&UKLogo.webp 1x, @/assets/img/page/serviceAsset/aboutBricks&UKLogo@2x.webp 2x"/>
            <img src="@/assets/img/page/serviceAsset/aboutBricks&UKLogo.png" srcset="@/assets/img/page/serviceAsset/aboutBricks&UKLogo.png 1x, @/assets/img/page/serviceAsset/aboutBricks&UKLogo@2x.png 2x" alt="税理士法人Bricks&UK"/>
          </picture>
          <p>税理士法人Bricks&UK</p>
        </div>
      </div>
    </div>
    <div class="w-m-cont mx-auto">
      <p class="mb-32-40">資産形成についての国内での農業コーヒー豆栽培事業とタイ王国でのCBD医療用大麻・クラトム栽培事業のご紹介も行なっております。</p>
      <table class="table-horizontal w-full">
        <tr>
          <th>特許権所有会社</th>
          <td>凍結解凍覚醒技術株式会社</td>
        </tr>
        <tr>
          <th>発明者</th>
          <td>田中節三</td>
        </tr>
        <tr>
          <th></th>
          <td>
            <ul class="ul-auto">
              <li>発明者：田中節三</li>
              <li>D&Tファーム</li>
              <li>FTAアグリビジネス株式会社</li>
              <li>FTATファーム株式会社</li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>