<template>
<div id="fv" class="
    bg-grad
    bg-cover
    text-white
    pt-20
    pb-40
    md:py-40
    z-40
    relative
    flex
    flex-col-reverse
    md:flex-row
    gap-[10vh]
    md:gap-0
    md:items-center
">
    <section class="fv-left w-[90vw] mx-auto z-[99] md:w-[60vw]">
        <h1 class="font-serif tracking-widest flex flex-wrap gap-x-3 gap-y-5 mb-14 drop-shadow-xl">
            <span>真心込めて</span>
            <span>親切丁寧に</span>
            <span>人生に寄り添う</span>
            <span class="l w-full flex flex-wrap gap-y-2">
                <span>最上級の</span>
                <span>サービスを</span>
            </span>
        </h1>
        <p class="leading-extra-loose mb-14">グランドプロジェクト株式会社は皆様の幸せのため<br/>今までにない、私たちにしか提供できないサービスを提供するため
            <br/>社員一同、同じ目標を持ち向上してまいります。</p>
        <router-link class="btn btn-white ml-auto md:ml-0" to="/company">会社情報を見る</router-link>
    </section>
    <div class="
        relative
        w-[80vw] 
        md:w-[40vw]
        mx-auto
        md:mx-0
    ">
        <img class="
            fv-text
            absolute
            inset-0
            mix-blend-overlay
            drop-shadow-2xl
            h-[calc(var(--vh,_1vh)_*_57)]
            md:h-[calc(870_/_1050_*_(var(--vh,_1vh)_*_100))]
            opacity-90
            z-10
            mx-auto
            md:mx-[unset]
        " src="@/assets/img/top/fvText.svg"/>
        <div class="
            relative
            z-10
            [&>picture]:-z-10
            [&>picture]:absolute
            [&>picture]:shadow-2xl
            max-w-[480px]
            md:max-w-[unset]
            mx-auto
            md:mx-0
        ">
            <img class="
                fv-text
                mix-blend-plus-lighter
                h-[calc(var(--vh,_1vh)_*_57)]
                md:h-[calc(870_/_1050_*_(var(--vh,_1vh)_*_100))]
                opacity-10
                z-10
                mx-auto
                md:mx-[unset]
            " src="@/assets/img/top/fvText.svg"/>
            <picture class="
                fv-img
                w-[calc((200_/_768)_*_100%)]
                md:w-[calc((160_/_1680)_*_100vw)]
                h-auto
                right-[calc(160_/_1680_*_100vw)]
                top-[calc(28_/_1050_*_(var(--vh,_1vh)_*_100))]
            ">
                <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/fv01_sp.webp"/>
                <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/fv01_sp.jpg"/>
                <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/fv01.webp 1x, @/assets/img/top/fv01@2x.webp 2x"/><img src="@/assets/img/top/fv01.jpg" srcset="@/assets/img/top/fv01.jpg 1x, @/assets/img/top/fv01@2x.jpg 2x" alt=""/>
            </picture>
            <picture class="
                fv-img
                w-[calc((320_/_768)_*_100%)]
                md:w-[calc((270_/_1680)_*_100vw)]
                h-auto
                left-none
                md:left-[calc(-150_/_1680_*_100vw)]
                bottom-[calc(160_/_768_*_(var(--vh,_1vh)_*_100))]
                md:bottom-[calc(328_/_1050_*_(var(--vh,_1vh)_*_100))]
            ">
                <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/top/fv02_sp.webp"/>
                <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/top/fv02_sp.jpg"/>
                <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/top/fv02.webp 1x, @/assets/img/top/fv02@2x.webp 2x"/><img src="@/assets/img/top/fv02.jpg" srcset="@/assets/img/top/fv02.jpg 1x, @/assets/img/top/fv02@2x.jpg 2x" alt=""/>
            </picture>
        </div>
    </div>
    </div>
</template>
    
<script>
//- gsap
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'firstView',
    components: {
        
    },
    mounted () {
        gsap.fromTo('.fv-text, .fv-left',
            {
                y: 0
            },
            {
                y: 100,
                scrollTrigger: {
                    trigger: 'body', 
                    start: 'top top', 
                    end: 'top -150%',
                    scrub: 1,
                }
            }
        );
    }
}
</script>

<style lang="scss" scoped>
#fv {
    @apply md:p-[120px_0_160px_clamp(5rem,_-5rem_+_15.63vw,_10rem)];
    section {
        h1 {
            font-size: clamp(1.25rem, -0.25rem + 2.34vw, 2rem);
            span {
                &.l {
                    font-size: clamp(3rem, 1rem + 3.13vw, 4rem);
                }
            }
        }
        p {
            font-size: clamp(1rem, 0.75rem + 0.39vw, 1.125rem);
        }
    }
}
</style>