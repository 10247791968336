<template>
<div id="vision" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">私たちが目指す場所</h2>
    <p class="w-m-cont mx-auto mb-60-80">
      <span class="block">現代は、100年時代。</span>
      <span class="block">私たちは既に資産を築かれている方だけではなく、20~30代のこれから資産形成を考えておられる方にもサービスを提供したいと考えています。</span>
      <span class="block">日頃から「詐欺」などの言葉を耳にすることが多い現在では、20代から投資と聞くと怪しい、信用できないと考える方も多いでしょう。</span>
      <span class="block">NISA（少額投資非課税制度）やiDeco（個人確定型拠出年金）など、国が進めている施策も浸透しきってはいません。</span>
    </p>
    <div class="miniNavigator">
      <p class="bg-gy">いま、投資や資産形成に<br>年齢は関係ありません</p>
      <img src="@/assets/img/common/navigator01.png">
    </div>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>