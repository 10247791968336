<template>
  <table class="table-horizontal w-s-cont m-auto">
    <tr>
      <th>会社名</th>
      <td>グランドプロジェクト株式会社</td>
    </tr>
    <tr>
      <th>住所</th>
      <td>
        <p>グランドグループ本社</p>
        〒103-0004 東京都中央区東日本橋2-24-12 東日本橋槇町ビル6F
        <!--<p>グランドプロジェクト株式会社 東京本社</p>
      東京都江戸川区瑞江2－46－17 YMDビル 2F
      <p>グランドケアシステム株式会社 本社</p>
      〒103-0004 東京都中央区東日本橋2-24-12 東日本橋槇町ビル6F-->
      </td>
    </tr>
    <tr>
      <th>事業内容</th>
      <td>
        <ul class="ul-auto">
          <li>介護付リゾートホテル運営管理事業</li>
          <li>有料老人ホーム運営管理事業</li>
          <li>介護・機能訓練付ゴルフレッスン事業</li>
          <li>投資事業</li>
          <li>農業事業</li>
          <li>広告代理店事業</li>
          <li>通信販売事業</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th>創業</th>
      <td>1992年11月</td>
    </tr>
    <tr>
      <th>設立</th>
      <td>2020年11月</td>
    </tr>
    <tr>
      <th>資本金</th>
      <td>1,000万円</td>
    </tr>
    <tr>
      <th>社員数</th>
      <td>正社員23名、コールスタッフ3名、介護スタッフ58名</td>
    </tr>
    <tr>
      <th>関連会社</th>
      <td>
        <a
          class="underline"
          href="https://grandcaresystem.co.jp"
          target="_blank"
          >グランドケアシステム株式会社</a
        >
      </td>
    </tr>
  </table>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
table {
  tr {
    td {
      p {
        font-weight: bold;
      }
    }
  }
}
</style>
