<template>
<div id="recommendStock" class="py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">弊社で会員権在庫のあるゴルフ場</h2>
    <golfCourceListStock />
    <router-link class="btn btn-bl mt-32-40 ml-auto justify-end" to="/golf-stock">一覧を見る</router-link>
  </section>
</div>
</template>

<script>
import golfCourceListStock from '@/components/page/golf/golfCourceListStock.vue'

export default {
  components: {
    golfCourceListStock
  }
}
</script>

<style lang="scss" scoped>

</style>