<template>
<div id="care" class="bg-gy py-l-block leading-loose">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">多様化する“介護”</h2>
    <p class="w-m-cont mx-auto mb-60-80">介護の需要は高齢化する日本において多様化しています。
      <br>実際にこのページをご覧になっている皆さんも、自分自身の介護について考えられていたり、親の介護について悩んでいたりするのではないでしょうか？</p>
    <section class="w-m-cont mx-auto mb-60-80">
      <h3 class="h3-auto">家族介護か、施設介護か</h3>
      <p class="mb-16-20">自身や親の介護について考えるにあたって、まず一番に考えるべきことは家族介護か、施設介護かについてでしょう。双方にメリット・デメリットがありますのでこちらで解説いたします。</p>
      <section class="bg-white p-32-40 mb-16-20">
        <h4 class="h4-auto">家族介護のメリット</h4>
        <p>
          <span class="block">まず、介護を受ける本人、介護をする側の家族の満足度が上がることです。</span>
          <span class="block">施設介護で定期的に面会する場合と、家族介護で日常を一緒に過ごすことができるのとでは、時間の面で大きな差が出てきます。</span>
          <span class="block">介護をする側としても、本人に寄り添っているという大きな実感を得ることができるでしょう。</span>
          <span class="block">情報量から見ても本人について、家族が知っている情報と施設担当が知っている情報量にも差が出てきます。それは些細なことで、好きなお菓子や歌手などなど。</span>
        </p>
      </section>
      <section class="bg-white p-32-40">
        <h4 class="h4-auto">施設介護のメリット</h4>
        <p class="mb-16-20">
          <span class="block">もう一方の選択肢でもある施設介護のメリットは、経験もあるプロが介護にあたれることです。</span>
          <span class="block">先ほど挙げた家族介護においては、家族が疲れやストレスを溜め込んでしまうという課題があります。</span>
          <span class="block"><b>「家族だから。」</b></span>
          <span class="block"><b>「家族として。」</b></span>
          <span class="block">と考えるのは当然ですが、それを理由に無理をしてしまうのも考えものです。</span>
          <span class="block"><b>介護方法は本当に正しいのか？</b></span>
          <span class="block"><b>施設に入居した方がお互い幸せだったのか？</b></span>
          <span class="block">など考えごとはつきません。</span>
          <span class="block">もう少し深掘りしてみると、家族介護の場合、疲れやストレスの原因は大きく3つに分けられます。</span>
        </p>
        <ul class="ul-auto
          [&>li]:flex
          [&>li]:items-start
          [&>li]:before:relative
          [&>li]:before:top-[calc(1em_-_.25em)]
          [&>li>div]:w-[calc(100%_-_1.5rem)]
        ">
          <li class="mb-16-20">
            <div>
              <p class="font-bold">経済的負担</p>
              <p>
                <span class="block">施設介護と比べると抑えられますが、多くの準備が必要になります。</span>
                <span class="block">例えば、食事代、介護用のベッド、おむつ、大きな金額がかかるものだと送迎用の車や、生活する家の内装工事なども必要な場合があります。</span></p>
            </div>
          </li>
          <li class="mb-16-20">
            <div>
              <p class="font-bold">身体的負担</p>
              <p>
                <span class="block">家族介護には食事の補助や、トイレや入浴の介助など24時間体制での補助が必要です。</span>
                <span class="block">認知症が進行している場合だと徘徊やせん妄症状への対処は身体的な負担もかかってきます。</span>
              </p>
            </div>
          </li>
          <li class="mb-16-20">
            <div>
              <p class="font-bold">精神的負担</p>
              <p>
                <span class="block">定期的な病院への送迎や、服用している薬の管理、自立して生活をすることがどんどん難しくなっていく本人への世話は増えていきます。</span>
                <span class="block">仕事で疲れて帰ってきている中、自分の時間が減り、かつストレスは溜まり、介護うつ病を発症することもあります。</span>
                <span class="block">介護うつ病は治療に時間がかかるため注意が必要です。</span>
              </p>
            </div>
          </li>
        </ul>
      </section>
    </section>
    <section>
      <h3 class="h3-auto w-f-cont mx-auto">大切なことはまず相談すること</h3>
      <div class="col2 w-f-cont">
        <p>
          <span class="block">介護において大事なこと、それはまず相談することです。</span>
          <span class="block">本人の意思、介護をする意思、そして我々介護を専門としている者の三者の目線を加えることで、それぞれの家族にあった“介護”を作り出しましょう。</span>
          <span class="block">私たちが提供できるサービスの中から最適な方法を提案いたします。</span>
        </p>
        <picture>
          <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceCare/care01_sp.webp"/>
          <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceCare/care01_sp.jpg"/>
          <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceCare/care01.webp 1x, @/assets/img/page/serviceCare/care01@2x.webp 2x"/>
          <img src="@/assets/img/page/serviceCare/care01.jpg" srcset="@/assets/img/page/serviceCare/care01.jpg 1x, @/assets/img/page/serviceCare/care01@2x.jpg 2x" alt=""/>
        </picture>
      </div>
    </section>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>