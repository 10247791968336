<template>
<nav>
    <ul class="nav">
        <li>
            <router-link to="/">トップページ</router-link>
        </li>
        <li class="parent">運営事業
            <div class="child">
                <ul>
                    <li>
                        <router-link to="/health">健康管理</router-link>
                    </li>
                    <li>
                        <router-link to="/care">介護管理</router-link>
                    </li>
                    <li>
                        <router-link to="/asset">資産管理</router-link>
                    </li>
                </ul>
            </div>
        </li>
        <li class="parent">ゴルフ場会員権
            <div class="child">
                <ul>
                    <!--li>
                        <router-link to="/golf-recommend">おすすめのゴルフ場</router-link>
                    </!--li-->
                    <li>
                        <router-link to="/golf-stock">会員権の在庫</router-link>
                    </li>
                </ul>
            </div>
        </li>
        <li>
            <router-link to="/company">会社情報</router-link>
        </li>
        <li>
            <router-link to="/access">アクセス</router-link>
        </li>
        <li>
            <a href="https://blog.grandproject.co.jp" target="_blank">ゴルフ会員権メディア</a>
        </li>
        <li>
            <a href="https://twitter.com/grandp_golf" target="_blank">Twitter</a>
        </li>
        <li>
            <router-link to="/contact">お問い合わせ</router-link>
        </li>
        <li>
            <router-link to="/privacy">プライバシーポリシー</router-link>
        </li>
    </ul>
</nav>
</template>

<script>
export default {
    name: 'commonNav',
    props: {
    }
}
</script>