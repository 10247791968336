<template>
<div id="vision" class="bg-gy py-l-block leading-loose overflow-hidden">
  <section class="w-f-cont m-auto">
    <h2 class="h2-auto">私たちが目指す場所</h2>
    <section class="w-m-cont mx-auto mb-60-80">
      <h3 class="h3-auto lg:text-center">まずは、皆様ご存知でしょうか？</h3>
      <p class="mb-32-40">
        <span class="block">認知症の発症数は10年前と比べても130万人ほど増加しており、年を重ねるにつれて発症率も上がります。</span>
        <span class="block">日本では<b>65歳以上のおおよそ6人に1人が認知症有病者</b>と言われています。</span>
        <span class="block">更に、認知症は高齢者の疾患と思われがちですが、現代では18~39歳でも認知症を発症する<b>若年性認知症（一般）の発症数も上昇傾向</b>にあります。</span>
      </p>
      <div class="bg-white visionNavigatorWrap">
        <h4 class="h4-auto">そもそも認知症とは？</h4>
        <p>認知症とは、脳の病気や障害など様々な原因により、日常生活全般に支障が出てくる状態を言います。
          <br>
          <br>
          具体的な症状としては、</p>
        <ul class="ul-auto ml-4">
          <li>もの忘れ</li>
          <li>時間・場所が分からなくなる</li>
          <li>理解力・判断力が低下する</li>
          <li>仕事や家事・趣味、身の回りのことができなくなる</li>
          <li>行動・心理症状（BPSD）</li>
        </ul>
        <p class="mb-32-40">などがあげられます。</p>
        <p class="text-sm mb-32-40 md:md-0">引用：厚生労働省 認知症（
          <a class="underline" href="https://www.mhlw.go.jp/kokoro/know/disease_recog.html" target="_blank">https://www.mhlw.go.jp/kokoro/know/disease_recog.html</a>
          ）</p>
        <div class="visionNavigator">
          <p>年齢に関係なく<br>発症する病気なんです</p>
          <picture>
            <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.webp"/>
            <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/common/navigator01.png"/>
            <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/common/navigator02.webp 1x, @/assets/img/common/navigator02@2x.webp 2x"/>
            <img src="@/assets/img/common/navigator02.png" srcset="@/assets/img/common/navigator02.png 1x, @/assets/img/common/navigator02@2x.png 2x" alt=""/>
          </picture>
        </div>
      </div>
    </section>
    <div class="col2 w-f-cont">
      <picture>
        <source type="image/webp" media="(max-width:767px)" srcset="@/assets/img/page/serviceHealth/vision01_sp.webp"/>
        <source type="image/jpeg" media="(max-width:767px)" srcset="@/assets/img/page/serviceHealth/vision01_sp.jpg"/>
        <source type="image/webp" media="(min-width:768px)" srcset="@/assets/img/page/serviceHealth/vision01.webp 1x, @/assets/img/page/serviceHealth/vision01@2x.webp 2x"/>
        <img src="@/assets/img/page/serviceHealth/vision01.jpg" srcset="@/assets/img/page/serviceHealth/vision01.jpg 1x, @/assets/img/page/serviceHealth/vision01@2x.jpg 2x" alt=""/>
      </picture>
      <p>認知症が増加傾向にある日本において、有効となるのがゴルフだと我々グランドプロジェクト株式会社は考えております。<br>
        <span class="block">まず、ゴルフ場のコースを1周歩くことで<b>運動不足の解消</b>に繋がります。</span>
        <span class="block">それからボールをホールに入れようとする際に高まる<b>集中力</b>、パートナーや同伴者との会話をする際の<b>コミュニケーション</b>など。全ての行動が認知症の予防に繋がります。</span>
        <span class="block">（国立長寿医療センターでは、認知症の予防に適度な運動や頭を使う活動として、人との交流ができるゴルフを推奨しています。）</span>
        <span class="block">また、ゴルフは単純に楽しく、とても魅力的なスポーツです。実際に当社内でもゴルフに魅了されてしまった社員は少なくありません。</span>
        <span class="block">お客様には、ゴルフをプレイする中で認知症予防につなげていただき、優雅な人生の一助になること。</span>
        <span class="block">そしてその全ての魅力をあなたに伝えることが私たちの使命であり、想いです。</span>
      </p>
    </div>
  </section>
</div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>

</style>