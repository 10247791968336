<template>
<div id="benefit" class="bg-gy py-l-block leading-loose">
  <section class="w-f-cont m-auto mb-60-80">
    <h2 class="h2-auto">ゴルフから得られる効果</h2>
    <p>先程、ゴルフの効果については簡単に書きましたが、他にも様々なメリットがあります。</p>
  </section>
  <div class="relative w-m-cont mx-auto z-0">
    <div class="
      relative
      after:content-['']
      after:block after:w-5 after:h-5
      after:border-t after:border-bl
      after:-rotate-45 after:origin-top-right
      after:absolute after:-bottom-5 after:right-[calc(350_/_800_*_100%)]
    ">
      <swiper
        :modules="modules"
        :pagination="pagination"
        :navigation="navigation"
        class="
        bg-white 
        border-b border-bl

        [&_.swiper-slide]:p-32-40
        ">
        <swiper-slide>
          <h3 class="h3-auto">緑豊かな地で自然の空気を感じる</h3>
          <p>
            <span class="block">ゴルフをワンラウンドする際に、コースにもよりますが平均的には10kmの距離を歩く必要があります。</span>
            <span class="block">10kmと数字に驚くもいらっしゃるかもしれません。</span>
            <span class="block">確かに、10kmのマラソンやウォーキングはとても大変ですし、実際にとても長い距離ですが、ゴルフをプレイする中ではそれほど距離を感じることはありません。</span>
          </p>
        </swiper-slide>
        <swiper-slide>
          <h3 class="h3-auto">運動不足の解消</h3>
          <p>
            <span class="block">ゴルフ場の広大な土地は、基本的に自然に囲まれています。</span>
            <span class="block">そんなゴルフ場に赴くことで、綺麗な空気を吸いながら、自然の恩恵を感じてリフレッシュし、いつもと違った雰囲気を感じ取ることができます。</span>
            <span class="block">自然の中に多く含まれる「緑色」は、</span>
          </p>
          <ul class="ul-auto">
            <li>安らぎ</li>
            <li>癒し</li>
            <li>穏やか</li>
          </ul>
          <p>といった印象を人に与えます。</p>
          <p>耳に流れ込んでくる、野鳥の鳴き声、風に揺れる木々、水が流れる音なども、脳を活性化させわたしたちに良い影響を与えます。</p>
        </swiper-slide>
        <swiper-slide>
          <h3 class="h3-auto">コミュニケーションが取れる</h3>
          <p>
            <span class="block">「ゴルフができるビジネスマンは、仕事ができる。」これはあながち間違っていません。</span>
            <span class="block">ビジネスマンにとって重要な要素であるコミュニケーション能力、人脈はゴルフによって築くことができます。</span>
            <span class="block">ゴルフを通して必要な同伴者、パートナーへの気遣いは、人生の中で他の場面でも必ず役立ちます。</span>
            <span class="block">また、認知症予防の観点から見ても「コミュニケーションを通して、人と繋がりを持つこと」は、重要といわれています。</span>
            <span class="block">これからゴルフを始められる方にとって、予期せぬ新たな出会いがあり、それによってあなたの人生が豊かになることでしょう。</span>
          </p>
        </swiper-slide>
        <swiper-slide>
          <h3 class="h3-auto">ゴルフ場巡りができる</h3>
          <p>ゴルフ場は場所によって、全く色が違います。<br>
            <br>
            <span class="block">全体の距離、傾斜の多いコース、木々が多いコース、川が多いコースなど。</span>
            <span class="block">ゴルフだけでなく、場所の特徴を楽しむこともできます。自分だけの楽しみ方を見つけて新たなゴルフ場に行くのもまた一興。</span>
            <br>
            <span class="block">移動の範囲を決めて、ゴルフ場をコンプリートするのも良いかもしれません。運転が好きだけど年を重ねるに連れて車に乗る機会がほとんどない、、、そんな方にもおすすめです。</span>
          </p>
        </swiper-slide>
      </swiper>
    </div>
    <p class="benefit-pagination
      text-[clamp(1.25rem,_0.75rem_+_0.78vw,_1.5rem)]
      font-serif
      text-bl
      flex justify-end items-center gap-4
    ">
    </p>
    <button class="benefit-navigation benefit-prev
      before:border-r-[12px]
      -left-[min(6.5vw,_50px)]
      sm:-left-16
      z-10
    "></button>
    <button class="benefit-navigation benefit-next
      before:border-l-[12px]
      -right-[min(6.5vw,_50px)]
      sm:-right-16
      z-10
    "></button>
  </div>
  <div class="miniNavigator">
    <p class="bg-gy">健康のための運動を<br>楽しんで続けられます！</p>
    <img src="@/assets/img/common/navigator01.png">
  </div>
</div>
</template>

<script>
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

const navigation = {
  prevEl: '.benefit-prev',
  nextEl: '.benefit-next',
};

const pagination = {
  el: ".benefit-pagination",
  type: 'fraction',
  formatFractionCurrent: function (number) {
    return '0' + number;
  },
  formatFractionTotal: function (number) {
    return '0' + number;
  },
  renderFraction: function (currentClass, totalClass) {
    return '<span class="' + currentClass + '"></span><span class="block w-6 sm:w-10 border-t border-bl"></span><span class="opacity-50 ' + totalClass + '"></span>';
  }
};

export default {
  components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation, Pagination],
        navigation,
        pagination,
      };
    },
}
</script>

<style lang="scss" scoped>
.benefit-navigation {
  width: clamp(3.125rem, 1.875rem + 1.95vw, 3.75rem);
  @apply aspect-square
    bg-bl
    flex items-center justify-center
    absolute
    top-[45%]
    before:content-['']
    before:block
    before:border-x-white
    before:border-y-transparent
    before:border-y-[7px];
  &.swiper-button-disabled {
    opacity: .3;
  }
}
</style>